
/* eslint-disable vue/no-unused-components */
import { defineComponent, PropType, watch, ref, onMounted, nextTick } from "vue";
import {scrollTo} from '@/utils'
import scroll from '@/scroll'
import ArticleBox from '@/components/ArticleBox.vue'
import Isotope from '@/components/ux/IsotopeTwo.vue'
import pageData from '@/pageData/research'
import ScrollEnter from '@/components/ScrollEnter.vue'
import {windowWidth} from '@/windowWidth'
import {isMobile} from '@/isMobile'

export default defineComponent({
    name: "App",
    props: {
        headImage: {
            default: () => {return ""},
        },
        currTab: {
            default: "热点专题",
        },
    },
    components:{
        ArticleBox,
        Isotope,
        ScrollEnter,
    },
    setup(props){
        const idHash = (str:string) => {
            return btoa(encodeURIComponent(str))
        }
        const isoInitialize = ref(0)

        onMounted(()=>{
            isoInitialize.value ++ 
        })

        
        watch(()=>pageData.value, ()=>{nextTick(()=>isoInitialize.value ++ )
        })
        watch(()=>props.currTab, ()=>{
            nextTick(()=>isoInitialize.value ++ )
        })

        window.addEventListener('resize', ()=> isoInitialize.value ++ )

        const handleNewWidth = (newWidth:number, mark:string) => {
            if(windowWidth.value < 800)return
            const wrapElem = document.querySelector(`._block_piece[mark="${mark}"]`) as HTMLElement
            if(!wrapElem) return
            nextTick(()=>{
                const wrapWidth = wrapElem.getBoundingClientRect().width
                wrapElem.style.paddingLeft = `${(wrapWidth - newWidth)/2}px`
                wrapElem.style.paddingRight = `${(wrapWidth - newWidth)/2}px`
            })
        }

        return {
            pageData,
            isoInitialize,
            scroll,
            scrollTo,
            idHash,
            handleNewWidth,
            windowWidth,
            encodeURIComponent
        }
    }
});

export default {

    blocks: [
    {
      title:'最新动态',
      summary:'',
      children:[
        {
          title:'',
          children:[
            {
                title:'品牌大数据 | 经典奢华 洲际酒店',
                date:'2021-12-23',
                content:[
                  '洲际酒店及度假村（Intercontinental Hotels&Resorts）是洲际酒店集团旗下的经典奢华品牌。70多年来，洲际品牌不断开拓全新旅行目的地，从传奇的历史经典酒店，地标性的城市酒店，到令人沉醉的度假村，洲际将世界各地的文化习俗与当地知识相融合，为宾客奉上奢华的全球旅行体验。',
                ]
            },
          ]
        },
      ]
    },
    {
      title:'研究回顾',
      summary:'',
      children:[
        {
          title:'',
          children:[
            {
              title:'城市大数据 | 山水甲天下 桂林',
              date:'2021-12-23',
              content:[
                '桂林，广西壮族自治区辖地级市。桂林自古享有“山水甲天下”之美誉，以山水、人情、风俗和悠闲舒适的生活而闻名。桂林风景秀丽，有以漓江风光和喀斯特地貌为代表的山水景观；此外，桂林还有壮、瑶、苗、侗等十多个少数民族独特的民俗风情文化，为这片土地更添万千风情。截至2020年，桂林市常住人口约为493万人。'
              ]
            },
          ]
        },
      ]
    },
  ]
}
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-55e7d7de"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "_page_wrap_clients" }
const _hoisted_2 = ["id"]
const _hoisted_3 = ["mark"]
const _hoisted_4 = { class: "_block_title1 _priv_title" }
const _hoisted_5 = { class: "_block_container" }
const _hoisted_6 = ["src"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "_block_content_wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_scroll_enter = _resolveComponent("scroll-enter")!
  const _component_Isotope = _resolveComponent("Isotope")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pageData, (block) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "_page_block_clients",
        key: block.title,
        id: _ctx.idHash(block.title)
      }, [
        _createElementVNode("div", {
          class: "_block_piece",
          mark: block.title
        }, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(block.title), 1),
          _createVNode(_component_Isotope, {
            activated: _ctx.windowWidth>600,
            class: "_block_piece_children",
            initialize: _ctx.isoInitialize,
            onNewWidth: newWidth=>_ctx.handleNewWidth(newWidth, block.title)
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(block.children, (b1, b1Index) => {
                return (_openBlock(), _createBlock(_component_scroll_enter, {
                  class: "iso_item",
                  _inactive: b1Index<4,
                  duration: 1,
                  key: b1.title
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("div", null, [
                        (_ctx.clientImg[b1.title])
                          ? (_openBlock(), _createElementBlock("img", {
                              key: 0,
                              class: "_client_logo",
                              src: `https://oss.huamei2001.com/hm2001/cases/clients/${_ctx.clientImg[b1.title]}`,
                              alt: ""
                            }, null, 8, _hoisted_6))
                          : _createCommentVNode("", true),
                        (!_ctx.clientImg[b1.title])
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 1,
                              class: "_client_name",
                              innerHTML: _ctx.clientHTML[b1.title]
                            }, null, 8, _hoisted_7))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_8, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(b1.content.sort((a,b)=>_ctx.removeProvs(a).localeCompare(_ctx.removeProvs(b))), (b2) => {
                          return (_openBlock(), _createElementBlock("div", {
                            class: "_block_content default_text",
                            key: b2
                          }, _toDisplayString(_ctx.removeProvs(b2)), 1))
                        }), 128))
                      ])
                    ])
                  ]),
                  _: 2
                }, 1032, ["_inactive"]))
              }), 128))
            ]),
            _: 2
          }, 1032, ["activated", "initialize", "onNewWidth"])
        ], 8, _hoisted_3)
      ], 8, _hoisted_2))
    }), 128))
  ]))
}

/* eslint-disable vue/no-unused-components */
import { defineComponent, ref, onMounted, watch } from "vue";
import { PageData } from "@/types";
import scroll from '@/scroll'
import {windowWidth} from '@/windowWidth'
import {layout} from '@/layout'
import CallForAction from '@/components/CallForAction.vue'
import {useRoute, useRouter} from 'vue-router'

import Research from '@/views/Research.vue'
import ToolListHotels from '@/views/ToolListHotels.vue'
import ToolListMC from '@/views/ToolListMC.vue'
import ToolListBW from '@/views/ToolListBW.vue'
import CasesClients from '@/views/cases/CasesClients.vue'
import CasesRegion from '@/views/cases/CasesRegion.vue'
import CasesCategories from '@/views/cases/CasesCategories.vue'
import Services from '@/views/Services.vue'
import Contact from '@/views/Contact.vue'
import Home from '@/views/Home.vue'
import About from '@/views/About.vue'
import currTab from './currTab'
import {pages}  from '@/siteConfig'
import {isMobile} from '@/isMobile'

import {scrollCount, scrollId, scrollTo} from './homeScroll'

import HotelLocator from './HotelLocator/HotelLocator.vue'
import { modal } from "@/modal";
import router from "@/router";

export default defineComponent({
    name: "App",
    components:{
        Services,
        ToolListHotels,
        ToolListMC,
        ToolListBW,
        CallForAction,
        Research,
        CasesClients,
        CasesRegion,
        CasesCategories,
        Contact,
        About,
        Home,
        HotelLocator,
    },
    props: {
        brightness: {
            default: 1,
        },
        headImage: {
            default: "",
        },
        imageName: {
            default: "",
        },
        headVideo: {
            default: "",
        },
        pageTitle: {
            default: "",
        },
        pageSubTitle: {
            default: "",
        },
        pageHeadArticle: {
            default: "",
        },
        pageName:{
            default: "",
        },
        tabsControl:{
            default:false
        },
        defaultTab:{
            default: ''
        },
        titleColor:{
            default: 'white'
        },
        shadowColor:{
            default: 'black'
        },
        pageLayout:{
            default:''
        },
        tabs:{
            default:[] as string[]
        },
    },
    setup(props){
        const idHash = (str:string) => {
            return btoa(encodeURIComponent(str))
        }

        const route = useRoute()

        currTab.value = route.params.tab as string

        // console.log('route name', route.name)

        watch(()=>props.tabs, ()=>{return})// console.log('tabs', props.tabs))

        const tabHeight = ref(60)

        onMounted(()=>{
            window.addEventListener("scroll",()=>{
                const callTo = document.querySelector('#CallForAction')
                const tabsElem = document.querySelector('#scrollTab')
                if(!callTo)return
                if(!tabsElem)return
                const call =  callTo.getBoundingClientRect()
                if(call.top-62 < 60 ){
                    tabHeight.value = Math.max(call.top-62, 0)
                }else{
                    tabHeight.value = 60
                }
            })
        })
        
        
        watch(()=>props.defaultTab, ()=>currTab.value = props.defaultTab)
        watch(()=>currTab.value, ()=>{return})// console.log('currTab', currTab.value))
        
        const handleTab = (data:PageData) => {
            if(!data.blocks)return []
            return {...data, blocks:data.blocks.filter(x=>(!props.tabsControl) || x.title == currTab.value)}
        }

        const navBarStyle = ref('position:absolute;top:500px;')
        watch(()=>scroll.value, ()=>{
            navBarStyle.value = scroll.value > 440 ? 'position:fixed;top:60px;' : 'position:absolute;top:500px;'
            // const cfa = document.getElementById('CallForAction')
            // if(!cfa) return

        })


        const router = useRouter()
        const onClickTab = (name:string, tab:string) => {
            if(tab=='环酒历'){
                modal.display('扫描二维码打开“环酒历”小程序', 'https://hm-chuyu.oss-cn-shenzhen.aliyuncs.com/%E5%AE%98%E7%BD%91%E5%9B%BE%E7%89%87/2024/%E7%A4%BE%E4%BA%A4%E5%AA%92%E4%BD%93/%E7%8E%AF%E9%85%92%E5%8E%86QR.png')
                return
            }
            // if(tab=='登录\n华美云'){
            //     currTab.value = tab
            //     window.open('https://c.huamei2001.com/')
            //     return
            // }
            // console.log('clicked tab', name, tab)
            if(name=='services'){
                currTab.value = tab
                router.push(`/${name}#${encodeURIComponent(tab)}`)
            }else{
                currTab.value = tab
                router.push({name, params:{tab}})
            }
        }


        const onClickPageHeadArticle = () => {
            if(props.pageHeadArticle){
                window.open(props.pageHeadArticle)
            }
        }

        return {
            navBarStyle,
            onClickTab,
            pages,
            handleTab,
            currTab,
            scroll,
            layout,
            idHash,
            tabHeight,
            windowWidth,

            scrollCount,
            scrollId,
            scrollTo,
            route,
            isMobile,

            onClickPageHeadArticle
        }
    }
});

export default {
    '新建酒店':[
        {
            title:'新建酒店',
            images: [
                'https://oss.huamei2001.com/temp/decor/%E6%96%B0%E5%BB%BA%E9%85%92%E5%BA%97.jpeg',
            ],
            summary:'新建酒店项目从立项启动到酒店开业是一个漫长且复杂的过程，如果业主筹建团队缺乏足够的专业知识和实战经验，就可能因为“走弯路”而造成巨大的不可挽回的时间延误和投资浪费。深圳市华美顾问有限公司凭借服务于1000+新建酒店项目所积累的丰富经验和海量数据，为酒店业主提供酒店建设全过程咨询顾问服务。',
            reverse:false,
        },
        {
            title:'前期定位阶段',
            images: [
                'https://oss.huamei2001.com/temp/%E5%93%81%E7%89%8C%E7%AD%9B%E9%80%89%E5%8F%8A%E5%90%88%E5%90%8C%E8%B0%88%E5%88%A4.png',
            ],
            reverse:true,
            level: 2,
            children:[
            {
                title:'酒店定位及可⾏性研究',
                content:['已在400+城市为1000+ 新建酒店项⽬提供定位可研服务']
            },
            {
                title:'品牌筛选及合同谈判',
                content:[
                    '⻄安曲江W酒店',
                    '重庆江北嘴W酒店',
                    '厦⻔海投豪华精选+源宿酒店',
                    '深圳南⼭⾹格⾥拉酒店',
                    '深圳机场凯悦+嘉轩酒店',
                    '⻄安浐灞华⾢+假⽇酒店',
                    '贵州贵安北⽃湾开元酒店',
                    '张家界⾸冠华⾢酒店',
                ]
            },
            ]
        },
        {
            title:'设计及建设阶段',
            level: 2,
            reverse:false,
            images: [
                'https://oss.huamei2001.com/temp/%E8%AE%BE%E8%AE%A1%E9%A1%BE%E9%97%AE%E5%8F%8A%E8%AE%BE%E8%AE%A1%E7%AE%A1%E7%90%86.jpeg'
            ],
            children:[
            {
                title:'设计顾问及设计管理',
                content:[
                    '上海世博凯悦酒店',
                    '⻄安机场凯悦+嘉轩酒店',
                    '⻄安中晶华⾢酒店',
                    '安顺⻩果树洲际+假⽇酒店',
                    '盐城⼤洋湾希尔顿逸林酒店',
                    '厦⻔佰翔威斯汀酒店',
                    '厦⻔海⻄⾸座凯悦酒店',
                    '⾈⼭东港希尔顿酒店',
                    '芜湖苏宁环球喜来登酒店',
                    '⻄宁英迪格酒店',
                ]
            },
            ]
        },
        {
            title:'开业筹备阶段',
            level: 2,
            reverse:true,
            images: [
                'https://oss.huamei2001.com/temp/%E5%BC%80%E4%B8%9A%E7%AD%B9%E5%A4%87%E9%98%B6%E6%AE%B5.png'
            ],
            children:[
                {
                    title:'采购顾问及开业顾问',
                    content:[
                        '上海衡⼭路12号豪华精选酒店(现为上海衡⼭路12号华⾢酒店)',
                        '上海悦榕庄酒店',
                        '上海浦发盛捷壹滨江服务公寓',
                        '⼴州地铁都喜泰丽度假酒店',
                        '武汉中建万豪酒店',
                        '⻄安欧御盛美利亚⼤酒店',
                        '珠海格⼒东澳岛酒店群',
                        '东莞阳光保险酒店',
                    ]
                },
            ]
        }
    ],
    '改建酒店':[
        {
            title:'改建酒店',
            images: [
                'https://oss.huamei2001.com/temp/%E6%94%B9%E5%BB%BA%E9%85%92%E5%BA%97.jpeg',
            ],
            summary:`城市更新和国企资产整合推动⼤批⽼旧酒店开始改造升级，在以体验经济、流量经济、移
                        动互联⽹、5G、新零售、碳达峰碳中和为主旋律的新经济时代，酒店改造显然不再是“重新
                        装修”那么简单。深圳市华美顾问有限公司跨界思维勇敢创新，为酒店改造项⽬提供全新的多维服务。`,
            reverse:true,

            children: [
                {
                  title:'服务产品',
                  reverse:true,
                  content: [`⽬标市场调研、经营⽅案策划、改造⽅案设计、改造投资精算、机电设施改造评估、改造⽅案实施技术⽀持、经营⽅案实施培训⽀持及资源⽀持`],
                },
            ]
        },
        {
          title:'成功故事',
          images: [
              'https://oss.huamei2001.com/temp/%E6%94%B9%E5%BB%BA%E9%85%92%E5%BA%971.jpeg',
          ],
          reverse:false,
          level:2,
          content:[
            '⻓沙融通⻓城宾馆',
            '武汉融通置业好百年酒店',
            '三亚鲁能⼭海天JW万豪+傲途格酒店',
            '深圳招商万科盐⽥壹海城凯悦酒店',
            '深圳南海希尔顿酒店',
            '昆明华侨城世博园酒店群',
            '北京字节跳动酒店',
            '北京京能⾦泰天湖会议中⼼酒店',
            '北京中信国泰饭店',
          ]
        },
    ],
    '开业酒店':[
        {
            title:'开业酒店',
            images: [
                'https://oss.huamei2001.com/temp/%E5%BC%80%E4%B8%9A%E9%85%92%E5%BA%97.jpg',
            ],
            summary:`新冠疫情让本来就步履蹒跚的酒店业遭受了前所未有的沉重打击，⾯对异常严峻的困难
                        局⾯，酒店业主和管理公司应当相互⽀持共度时艰。深圳市华美顾问有限公司秉承解决问题向前看的正
                        能量思维，为酒店业主和管理公司提供基于⾏业⼤数据的客观中⽴的评估及优化服务。`,
                reverse:false,
            children:[
                {
                title:'服务产品',
                content: [`预算审核、业绩评估、资产评估、总经理⼯作评估、资产交易、资产证券化`],
                },
            ]
        },
        {
            title:'成功故事',
            reverse:true,
            images: [
                'https://oss.huamei2001.com/temp/%E5%BC%80%E4%B8%9A%E9%85%92%E5%BA%971.jpeg',
            ],
            level: 2,
            content: [
                '北京华贸丽思卡尔顿酒店',
                '北京万达索菲特⼤饭店',
                '上海浦东⽂华东⽅酒店',
                '上海建业⾥嘉佩乐酒店',
                '⼴州机场铂尔曼酒店',
                '海⼝⾹格⾥拉酒店',
                '珠海横琴索菲特酒店',
                '⻓沙万达⽂华酒店',
                '秦皇岛喜来登酒店',
            ]
        },
    ],
    '酒店集团':[
        {
            title:'酒店集团',
            images: [
                'https://oss.huamei2001.com/temp/%E9%85%92%E5%BA%97%E9%9B%86%E5%9B%A2.jpg',
            ],
            summary:`万科、恒⼤、华润、华侨城等拥有⼤量酒店项⽬的地产集团或旅游集团都在建⽴和发展⾃
                        主酒店管理品牌，并开 始⾛向世界在全球收购酒店资源，同时还积极尝试利⽤资产证券化
                        等⼿段盘活庞⼤的酒店资产。`,
            reverse:false,
            children:[
                {
                    title:'服务产品',
                    content: [`发展战略、品牌战略、资产交易、资产证券化`],
                },
            ]
        },
        {
            title:'成功故事',
            reverse:true,
            images: [
                'https://oss.huamei2001.com/temp/%E9%85%92%E5%BA%97%E9%9B%86%E5%9B%A21.jpeg',
            ],
            level: 2,
            content:[
                '上海通茂集团酒店品牌发展战略',
                '上海世茂集团酒店品牌发展战略',
                '上海地产酒店公寓板块发展战略',
                '招商美伦酒店品牌发展战略',
                '东莞万科酒店板块发展战略',
                '华夏幸福酒店板块发展战略',
                '⻰湖地产酒店板块发展战略',
                '成都蓝光集团酒店板块发展战略',
                '⼴州机场酒店板块发展战略',
                '万达⽂旅主题乐园酒店发展研究',
            ]
        },
    ],
    '酒店地产':[
        {
            title:'酒店地产',
            images: [
                'https://oss.huamei2001.com/temp/%E9%85%92%E5%BA%97%E5%9C%B0%E4%BA%A7.png',
            ],
            summary:`地产项⽬包含酒店是中国最普遍的项⽬类型，⽤酒店品牌和酒店星级提升地产档次促进
                        地产营销也是中国最普遍 的操盘⽅法。在某些地产项⽬中，⽤豪华酒店品牌冠名公寓
                        或别墅有机会⼤幅提升公寓或别墅的品质和形象，并因此卖出更⾼溢价。`,
                        reverse:false,
            children:[
                {
                    title:'服务产品',
                    content: [`可⾏性研究、酒店品牌引进、产品设计、营销推⼴、销售代理`],
                },
            ]
        },
        {
            title:'成功故事',
            images: [
                'https://oss.huamei2001.com/temp/%E9%85%92%E5%BA%97%E5%9C%B0%E4%BA%A71.jpeg',
            ],
            reverse:true,
            level: 2,
            content: [
                '丽江⾦林瑞吉酒店及瑞吉别墅',
                '庐⼭中航归宗项⽬酒店群',
                '惠州中航元屿海项⽬酒店群',
                '烟台⾦地公寓',
                '北京华夏泓基新国展酒店',
                '北海碧雅度假村',
            ]
        },
    ],
    '旅游度假⽬的地/城市中央活⼒区':[
        {
            title:`旅游度假⽬的地/城市中央活⼒区`,
            images: [
                'https://oss.huamei2001.com/temp/%E6%97%85%E6%B8%B8%E5%BA%A6%E5%81%87%E7%9B%AE%E7%9A%84%E5%9C%B0.png',
            ],
            tabDisplay:'旅游度假',
            reverse:false,
            summary:`旅游度假⽬的地不仅包括⾃然景区和主题公园，深圳市华美顾问有限公司认为城市中⼼中央活⼒区和城市
                        郊区短途度假区同样是 极有价值的旅游度假⽬的地，从伦敦东区到东京宫下公园，从上海
                        新天地到北京SKP-S，深圳市华美顾问有限公司⻓期追踪研究 各类旅游度假⽬的地的创新模式和发展趋势。`,
            children:[
                {
                    title:'服务产品',
                    content: [`发展战略、操作⽅案、资源整合、实施顾问`],
                },
            ]
        },
        {
            title:'成功故事',
            images: [
                'https://oss.huamei2001.com/temp/%E6%B7%B1%E5%9D%91.jpeg',
            ],
            reverse:true,
            level: 2,
            content: [
                '深圳宝安区酒店布局及规模研究',
                '浙江省江⼭市酒店产业规模及布局研究',
                '重庆巴南区中央活⼒区发展规划',
                '⻄宁南川中央活⼒区发展规划',
                '上海海银⾦控旅游度假⽬的地发展战略',
                '珠海格⼒东澳岛酒店群',
                '福州⼭⽔⽂园浪岐岛项⽬酒店群',
                '陵⽔海航珍珠海岸夏宫旅游⽬的地项⽬',
            ]
        },
    ],
    '特⾊⼩镇':[
        {
            title:`特⾊⼩镇`,
            images: [
                'https://oss.huamei2001.com/temp/%E7%89%B9%E8%89%B2%E5%B0%8F%E9%95%872.jpg',
            ],
            reverse:false,
            summary:`城市在雾霾化，农村在空⼼化，经济超速发展带来财富的同时也带来了影响⽣存基础的
                    严重问题，但是回归乡村 转变⽣活⽅式并不意味着只能追求简朴、逍遥和原⽣态。超
                    越⼯业⽤先进产业发展⼩镇，超越城市⽤先进⽂明改 造⼩镇，创造更好地⽣存空间和
                    ⽣活⽅式。`,
            children:[
                {
                    title:'服务产品',
                    content:['发展战略、操作⽅案、资源整合、实施顾问'],
                }
            ]
        },
        {
            title:'成功故事',
            images: [
                'https://oss.huamei2001.com//temp/%E7%89%B9%E8%89%B2%E5%B0%8F%E9%95%87%201.jpg',
            ],
            reverse:true,
            level: 2,
            content:[
                '湖州祥⽣南浔特⾊⼩镇',
                '湖州中通妙⼭特⾊⼩镇',
                '⾈⼭祥⽣登步岛特⾊⼩镇',
                '新昌梅溪湖特⾊⼩镇',
                '临汾五洲六村联建项⽬',
            ]
        },
    ],
    '幸福产业':[
        {
            title:`幸福产业`,
            images: [
                'https://oss.huamei2001.com/temp/%E5%85%BB%E8%80%81%E9%A1%B9%E7%9B%AE.jpg',
            ],
            reverse:false,
            summary:`为什么旅游、⽂化、体育、健康、养⽼等五⼤产业被联系在⼀起称为幸福产业？很⼤部分
                    的原因是资源业、加⼯业、房地产业、⾦融业等在过去的快速发展中给⼈们带来了严重的
                    不幸福感，最明显的例⼦就是我们的⾸都竟然成为雾霾和污染最严重的城市之⼀，我们应
                    该认识到应当为幸福⽽不是GDP发展经济。`,
            children:[
                {
                    title:'服务产品',
                    content:['发展战略、操作⽅案、资源整合、实施顾问'],
                }
            ]
        },
        {
            title:'成功故事',
            reverse:true,
            images: [
                'https://oss.huamei2001.com/temp/%E5%B9%B8%E7%A6%8F%E4%BA%A7%E4%B8%9A.jpg',
            ],
            level: 2,
            content:[
                '⾹港房协会元朗天⽔围养⽼项⽬',
                '沈阳合众⼈寿棋盘⼭养⽼社区项⽬',
                '乌镇平安养⽼社区项⽬',
                '南京银城养⽼社区项⽬',
                '哈尔滨⾠能郊区⼤盘导⼊养⽼服务项⽬',
                '⻄安华润港务区体育中⼼项⽬',
            ]
        }
    ],
}


/* eslint-disable vue/no-unused-components */
import { defineComponent, PropType, watch, ref } from "vue";
import {scrollTo} from '@/utils'
import scroll from '@/scroll'
import {windowWidth} from '@/windowWidth'
import Isotope from '@/components/ux/IsotopeTwo.vue'

export default defineComponent({
    name: "App",
    props: {
        headImage: {
            default: () => {return ""},
        },
    },
    components:{
        Isotope
    },
    setup(props){
        const idHash = (str:string) => {
            return btoa(encodeURIComponent(str))
        }

        return {
            scroll,
            scrollTo,
            idHash,
            windowWidth
        }
    }
});


export default {

    blocks: [
    {
      title:'项目分布',    
      summary:'',
      children:[
        {
          title:'全国',
        },
        {
          title:'北京',
        },
        {
          title:'上海',
        },
        {
          title:'深圳',
        },
        {
          title:'西安',
        },
      ]
    },
    {
      title:'典型客户',  
      reverse:true,
      summary:'',
      children:[
        {
          title:'房地产集团',
          children:[
            {
                title:'华夏幸福基业',
                content:[
                  '北京华夏泓基新国展酒店',
                  '北京华夏京御酒店',
                  '北京华夏万豪酒店项目',
                  '江门华夏幸福酒店',
                  '无锡华夏幸福酒店公寓',
                  '华夏基业酒店板块战略项目',
                  '华夏幸福广州酒店项目',
                  '郑州新郑华夏酒店项目',
                  '霸州华夏酒店项目',
                  '廊坊华夏幸福基业酒店项目',
                  '廊坊华夏潮白河喜来登酒店',
                  '廊坊华夏福朋喜来登酒店',
                  '张家口华夏酒店项目',
                  '嘉兴华夏南湖酒店项目',
                  '嘉善华夏幸福酒店项目',
                  '任丘华夏酒店项目',
                ]
            },
            {
                title:'华润',
                content:[
                  '北京华润崇文门酒店及公寓',
                  '北京华润生命科学产业园酒店',
                  '北京华润产业园酒店+公寓项目',
                  '北京华润置地酒店项目',
                  '西安浐灞华润体育中心项目酒店',
                  '重庆华润万象城北区酒店',
                  '成都华润彭州项目酒店',
                  '东莞华润国际商务区综合体酒店',
                  '淄博华润喜来登酒店',
                  '威海华润金线顶酒店',
                  '东莞华润地标酒店项目',
                  '重庆华润酒店项目',
                  '西安华润体育中心项目',
                  '成都彭州华润酒店项目',
                ]
            },
            {
              title:'招商地产',
              content:[
                '上海宝山招商酒店',
                '上海招商宝山酒店项目',
                '深圳招商华侨城红山项目酒店',
                '深圳招商万科盐田壹海城凯悦酒店',
                '深圳南海希尔顿酒店',
                '深圳盐田招商万科凯悦酒店',
                '博鳌招商大灵湖项目酒店',
                '招商美伦酒管自有品牌建设',
                '漳州卡达凯斯酒店',
                '昆山招商积余酒店',
                '烟台招商马尔贝拉项目美伦酒店',
                '招商美伦战略项目',
                '烟台招商•马尔贝拉美伦酒店',
                '博鳌招商大灵湖酒店项目',
              ]
            },
          ]
        },
        {
          title:'旅游酒店集团',
          children:[
            {
                title:'华侨城',
                content:[
                  '上海华侨城天祥项目酒店',
                  '广州华侨城增城白水寨项目酒店',
                  '深圳招商华侨城红山项目酒店',
                  '深圳东部华侨城酒店',
                  '深圳华侨城光明科学城项目酒店',
                  '深圳华侨城坪山聚龙山项目酒店',
                  '深圳华侨城宝安03-03地块酒店',
                  '深圳华侨城宝安08-11地块酒店',
                  '深圳华侨城荷棠里项目酒店',
                  '深圳华侨城海上田园项目酒店板块',
                  '深圳华侨城国际会展中心项目酒店',
                  '西安沣东华侨城项目酒店群',
                  '衡阳华侨城来雁新城酒店群',
                  '张家界华侨城项目酒店群',
                  '襄阳华侨城欢乐小镇民宿及陆公园酒店',
                  '襄阳华侨城南地块酒店',
                  '襄阳华侨城云海嘉途酒店',
                  '襄阳华侨城奇趣童年酒店',
                  '武汉华侨城东湖湾项目酒店',
                  '眉山华侨城中法164地块二期酒店',
                  '成都华侨城安仁集装箱酒店',
                  '昆明华侨城世博园酒店群',
                  '昆明华侨城第二总部酒店',
                  '江门华侨城古劳水乡酒店',
                  '佛山华侨城顺德项目酒店',
                  '南昌华侨城象湖项目酒店',
                  '宁波华侨城阳光海湾项目酒店',
                  '青岛华侨城项目酒店板块',
                  '济南章丘华侨城绣源河项目酒店',
                  '天津华侨城东丽湖酒店',
                ]
            },
            {
                title:'海旅集团',
                content:[
                  '茂名南海旅游岛酒店群',
                  '惠州南海旅游酒店',
                  '厦门路桥海旅酒店',
                  '厦门海旅祥露酒店',
                  '厦门海旅海沧古楼酒店',
                  '厦门海旅西山门酒店',
                  '厦门海旅东山门酒店',
                  '厦门海旅温德姆至尊酒店',
                  '厦门海旅酒店板块',
                  '厦门海旅豪生项目',
                ]
            },
          ]
        },
        {
          title:'⾦融机构及政府部⻔',
          children:[
            {
                title:'海旅集团',
                children:[
                  {
                      title:'中国建设银行',
                      content:[]
                  },
                  {
                      title:'中信证券',
                      content:[]
                  },
                  {
                      title:'中国平安',
                      content:[]
                  },
                ]
            },
          ]
        },
        {
          title:'各⾏业企业',
          children:[
            {
                title:'国家电网',
                content:[]
            },
            {
                title:'中冶集团',
                content:[]
            },
            {
                title:'中国东方航空',
                content:[]
            },
          ]
        },
      ]
    },
    {
        leftRightDepth:2,
      title:'项目分类',  
      reverse:true,
      summary:'',
      children:[
        {
          title:'城市新区/新兴城市先发酒店',
          image:'/images/categories/city.png',
          content:[
            '安顺黄果树洲际+假日酒店',
            '北京亦庄移动硅谷酒店',
            '常州新城希尔顿酒店',
            '成都利通酒店',
            '大连金石滩鲁能希尔顿酒店',
            '大连金石滩鲁能英迪格酒店',
            '鄂尔多斯康巴什世林酒店',
            '鄂尔多斯满世东胜酒店',
            '鄂尔多斯满世康巴什酒店',
            '贵安新区北斗湾开元酒店',
            '杭州钱塘新区智慧谷酒店',
            '杭州下沙金沙湖嘉悦里酒店',
            '衡阳华侨城来雁新城酒店群',
            '开原滨水新城酒店',
            '开原新创财富中心酒店',
            '南京金帆南部新城会展中心酒店',
            '南通南都新城酒店',
            '南通启东德汇酒店群',
            '宁德隆德福宁湾酒店',
            '钦州中马产业园酒店',
            '天津于家堡金融区酒店',
            '厦门海沧未来海岸酒店',
            '厦门海投豪华精选+源宿酒店',
            '厦门环东海域新城项目酒店群',
            '厦门中交酒店',
            '上海奉贤绿庭酒店',
            '上海奉贤万豪酒店',
            '深圳地铁前海湾交通枢纽酒店',
            '深圳坪山华侨城项目酒店',
            '深圳坪山会展中心酒店',
            '深圳前海腾讯大铲湾项目酒店',
            '唐山曹妃甸渤海国际大酒店',
            '西安沣东华侨城项目酒店群',
            '西安沣东昆明池酒店',
            '重庆中冶索梅丽亚酒店',
          ],
        },
        {
            title:'酒店群',
          image:'/images/categories/hotel.png',
          content:[
            "福建厦门环东海域新城项目酒店群 ",
            "云南昆明融创大湾酒店群 ",
            "广东茂名南海旅游岛酒店群 ",
            "陕西西安沣东华侨城项目酒店群 ",
            "安徽黄山盛浩金盆湾项目酒店群",
            "安徽黄山中信太平湖项目酒店群",
            "北京天恒酒店群",
            "北京玉渊潭酒店群",
            "福建福州融汇酒店群",
            "福建福州山水文园浪岐岛项目酒店群",
            "广东佛山南海中信山语湖项目酒店群",
            "广东佛山首创奥特莱斯项目酒店群",
            "广东惠州中航元屿海项目酒店群",
            "广东深圳绿景白石洲酒店群",
            "广东深圳卓越皇岗酒店群",
            "广东珠海格力东澳岛酒店群",
            "广西南宁龙象谷项目酒店群",
            "贵州凯里苗疆侗乡酒店群",
            "湖北武汉华侨城东湖湾项目酒店群",
            "湖北武汉华侨城项目酒店群",
            "湖北襄阳华侨城文旅项目酒店群",
            "湖南长沙金科仙人湖项目酒店群",
            "湖南长沙中信文化广场酒店群",
            "江苏苏州太湖梦幻世界酒店群",
            "江西庐山中航归宗项目酒店群",
            "江西庐山中信西海项目酒店群",
            "江西婺源山水文园项目酒店群",
            "辽宁沈阳合众人寿养老项目酒店群",
            "山东青岛华侨城项目酒店群",
            "山东威海明月酒店群",
            "山东烟台龙湖养马岛项目酒店群",
            "陕西西安乐华城酒店群",
            "陕西西安中贸项目酒店群",
            "四川成都麓湖项目酒店群",
            "四川成都森宇南湖万豪酒店群",
            "四川眉山华侨城中法科技园项目酒店群",
            "浙江嘉兴海盐山水文园酒店群",
            "重庆山水文园璧山项目酒店群",
            "湖南张家界华侨城项目酒店群 ",
            "江苏泰州金姜堰项目酒店群 ",
            "云南普洱融创国际健康城酒店群 ",
            "湖南衡阳华侨城来雁新城酒店群 ",
            "江苏南通启东德汇酒店群 ",
            "浙江宁波华侨城阳光海湾项目酒店群 ",
            "山东济南章丘华侨城绣源河项目酒店群"
          ],
        },
        {
            title:'温泉度假酒店',
          image:'/images/categories/beach-umbrella-and-hammock.png',
          content:[
            "成都宏懋雅高美爵温泉酒店仲裁项目",
            "北京天龙源汉慈温泉酒店",
            "广东从培温泉酒店",
            "广东怀集岳山温泉与金鸡山度假区",
            "广东清远勤天城温泉酒店",
            "广东中山港德温泉水疗酒店",
            "河北保定白洋淀温泉城芦乡国际酒店",
            "河北廊坊固安京御牛驼温泉酒店",
            "河南许昌大正温泉酒店",
            "湖北咸宁艳阳天温泉酒店",
            "江苏苏州吴江东太湖温泉度假酒店",
            "江苏苏州吴江东太湖温泉度假酒店",
            "江苏镇江智谷温泉酒店",
            "江西庐山星子天恒温泉",
            "江西宜春新中美温泉度假酒店",
            "山东青岛即墨金秋温泉酒店",
            "山东潍坊迪梦温泉小镇酒店",
            "陕西西安汤峪国际温泉城精品酒店",
            "陕西西安天台温泉度假村",
            "四川普格川能投螺髻山温泉特色小镇",
            "云南旅投邦腊掌温泉度假区",
            "浙江金华武义元利温泉酒店",
            "重庆翡翠湖温泉度假酒店",
            "重庆统景温泉酒店"
          ],
        },
        {
          title:'主题公园酒店',
          image:'/images/categories/ferris-wheel.png',
          content:[
              '北京天恒酒店群',
              '北京招商奥运公园酒店',
              '承德中冶主题公园群',
              '大理华侨城大理王宫项目酒店',
              '德州东海天下酒店',
              '湖州安吉银润主题公园项目酒店',
              '济南融创文旅城酒店',
              '丽江复华度假世界酒店',
              '三亚海昌海洋乐园项目酒店',
              '上海海昌极地海洋公园酒店',
              '万达室外乐园及电影乐园酒店对标研究',
              '万达水上乐园酒店对标研究',
              '西安浐灞绿地湿地公园项目酒店',
              '西安乐华城酒店群',
              '襄阳华侨城文旅项目酒店群',
              '烟台海昌酒店',
            ],
        },
        {
            title:'机场及航空城酒店',
          image:'/images/categories/takeoff.png',
          content:[
            '首都机场酒店',
            '深圳机场凯悦+嘉轩酒店',
            '广州机场铂尔曼酒店',
            '西安咸阳机场凯悦+嘉轩酒店',
            '西安咸阳机场云冠时代广场酒店',
            '长沙湖南空港项目酒店',
            '成都空港商务区项目酒店',
            '成都蓝光双流空港国际城酒店',
            '贵阳航空港酒店',
            '贵阳机场综合体酒店',
            '揭阳潮汕机场交通中心酒店',
            '宁波栎社机场东航酒店',
            '西安东航酒店',
            '张家界湖南空港项目酒店',
            '郑州机场大酒店',
            '郑州裕鸿航空港酒店',
            '重庆航悦酒店',
            '重庆喜地山戴斯酒店',
          ],
        },
        {
            title:'地铁轨交酒店',
          image:'/images/categories/metro.png',
          content:[
            "广东广州地铁都喜泰丽度假酒店",
            "广东深圳北站深圳地铁酒店",
            "广东深圳地铁皇冠假日酒店",
            "广东深圳地铁前海湾交通枢纽酒店",
            "广东深圳地铁塘朗酒店",
            "浙江杭州地铁武林广场酒店",
            "浙江杭州地铁下沙中心站酒店",
            "成都轨道交通集团有限公司新建酒店项目",
            "成都轨道交通集团有限公司新建酒店项目",
            "云南昆明轨交草海项目酒店 "
          ],
        },
        {
            title:'会展中心酒店',
            image:'/images/categories/briefcase.png',
          content:[
            "青海国际会展中心CAZ项目",
            "深圳会展中心会议宴会设施改造定位项目",
            "安徽合肥蓝鼎会展中心酒店",
            "广东深圳华侨城国际会展中心项目酒店",
            "广东深圳坪山会展中心酒店",
            "江苏南京金帆南部新城会展中心酒店",
            "四川遂宁中铁会展中心酒店",
            "浙江宁波会展中心泛太平洋酒店",
            "甘肃嘉峪关丝绸之路会展中心酒店 ",
            "青海西宁青海国际会展中心酒店 "
          ],
        },
        {
            title:'酒店+综合体',
          image:'/images/categories/shopping-mall.png',
          content:[
            "广东东莞华润国际商务区综合体项目酒店 ",
            "贵州贵阳机场综合体酒店 ",
            "河北廊坊来康郡酒店综合体",
            "江苏启东宾馆公司城市综合体酒店",
            "广东揭阳综合体酒店 ",
            "广东深圳宝安区12-03地块综合体项目 ",
            "陕西西安大明宫综合体项目酒店 "
          ],
        },
      ]
    },
  ]
}
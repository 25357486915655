
/* eslint-disable vue/no-unused-components */
import { defineComponent } from "vue";
import { scrollTo } from '@/utils'
import scroll from '@/scroll'
import researchData from '@/pageData/research'
import pageData from '@/pageData/home'
import TImageDecorSplash from '@/components/TImageDecorSplash.vue'
import TImageDecorBrush from '@/components/TImageDecorBrush.vue'
import TImageDecorVideo from '@/components/TImageDecorVideo.vue'
import { useRouter } from 'vue-router'
import ArticleBox from '@/components/ArticleBox.vue'

export default defineComponent({

    props: {
        headImage: {
            default: "",
        },
        currTab: { default: "" }
    },
    components: {
        TImageDecorSplash,
        TImageDecorBrush,
        TImageDecorVideo,
        ArticleBox
    },
    setup(props) {
        const idHash = (str: string) => {
            return btoa(encodeURIComponent(str))
        }

        return {
            router: useRouter(),
            pageData,
            researchData,
            scroll,
            scrollTo,
            idHash,
            window,
            aboutTexts: [
                "深圳市华美顾问有限公司是中国领先的酒店咨询公司。2001年深圳市华美顾问有限公司在中国深圳创立。",
                "致力于为酒店、酒店集团、酒店地产、旅游度假目的地、特色小镇、幸福产业等项目提供全面策划咨询服务。",
                "目前设有深圳、北京、上海、西安4个办公室，已在中国400+城市为1700+项目提供高质量咨询服务。"
            ]
        }
    }
});

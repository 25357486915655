import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-08920186"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "_page_wrap" }
const _hoisted_2 = ["id"]
const _hoisted_3 = ["mark"]
const _hoisted_4 = { class: "_block_title1 _priv_title" }
const _hoisted_5 = ["mark"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArticleBox = _resolveComponent("ArticleBox")!
  const _component_Isotope = _resolveComponent("Isotope")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pageData.blocks, (block) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "_page_block_research",
        key: block.title,
        id: _ctx.idHash(block.title)
      }, [
        (block.tab == _ctx.currTab)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "_block_piece",
              mark: encodeURIComponent(block.title)
            }, [
              _createElementVNode("div", _hoisted_4, _toDisplayString(block.title), 1)
            ], 8, _hoisted_3))
          : _createCommentVNode("", true),
        (block.tab == _ctx.currTab)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "_block_piece noTop",
              mark: block.title
            }, [
              _createVNode(_component_Isotope, {
                class: "_block_piece_children",
                initialize: _ctx.isoInitialize,
                activated: _ctx.windowWidth>700,
                onNewWidth: newWidth=>_ctx.handleNewWidth(newWidth, encodeURIComponent(block.title))
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(block.children, (b1, b1Ind) => {
                    return (_openBlock(), _createBlock(_component_ArticleBox, {
                      _inactive: b1Ind<4,
                      class: "_article_box",
                      key: b1.title,
                      article: b1
                    }, null, 8, ["_inactive", "article"]))
                  }), 128))
                ]),
                _: 2
              }, 1032, ["initialize", "activated", "onNewWidth"])
            ], 8, _hoisted_5))
          : _createCommentVNode("", true)
      ], 8, _hoisted_2))
    }), 128))
  ]))
}

/* eslint-disable vue/no-unused-components */
import { defineComponent, PropType, watch, ref, onMounted, nextTick } from "vue";
import {scrollTo} from '@/utils'
import scroll from '@/scroll'
import Isotope from '@/components/ux/IsotopeTwo.vue'
import pageData from '@/pageData/toolList'
import ScrollEnter from '@/components/ScrollEnter.vue'
import {windowWidth} from '@/windowWidth'

export default defineComponent({
    name: "App",
    
    props: {
        headImage: {
            default: () => {return ""},
        },
        currTab: {
            default: "热点专题",
        },
    },
    components:{
        Isotope,
        ScrollEnter
    },
    setup(props){

        

        const idHash = (str:string) => {
            return btoa(encodeURIComponent(str))
        }
        const isoInitialize = ref(0)

        onMounted(()=>{
            isoInitialize.value ++ 
        })

        watch(()=>pageData.value['管理集团及品牌'], ()=>{
            // console.log('pageData管理集团及品牌',pageData.value)
            nextTick(()=>isoInitialize.value ++ )
        })
        watch(()=>props.currTab, ()=>{
            // console.log('props.currTab watcg',props.currTab)
            nextTick(()=>isoInitialize.value ++ )
        })

        window.addEventListener('resize', ()=> isoInitialize.value ++ )
        

        const handleNewWidth = (newWidth:number, mark:string) => {
            // return
            const wrapElem = document.querySelector(`._block_piece[mark=${mark}]`) as HTMLElement
            if(!wrapElem) return
            nextTick(()=>{
                const wrapWidth = wrapElem.getBoundingClientRect().width
                wrapElem.style.paddingLeft = `${(wrapWidth - newWidth)/2}px`
                wrapElem.style.paddingRight = `${(wrapWidth - newWidth)/2}px`
            })
        }

        return {
            images: Array.from({ length: 29 }, (_, i) => `/images/brandwall/page${i + 1}.png`),
            pageData,
            isoInitialize,
            scroll,
            scrollTo,
            idHash,
            handleNewWidth,
            window,
            windowWidth
        }
    }
});



import axios from 'axios'
import {backEndURL} from '@/siteConfig'

const sectionNames = ['大型房地产集团','大型旅游酒店集团','各行业国企和民企','金融机构']

const nameMapping = {
    '大型房地产集团':"房地产集团",
    '大型旅游酒店集团':"旅游酒店集团",
    '各行业国企和民企':"各行业国企民企",
    '金融机构':"金融机构、行业组织、政府单位"
}

const weights = {
    "北京":9999,
    "上海":9998,
    "广州":9997,
    "深圳":9996,
  }
const sortCities = (a,b)=>{
    const a_head =  a.slice(0,2)
    const b_head =  b.slice(0,2)
    if(Object.keys(weights).includes(a_head) && Object.keys(weights).includes(b_head)){
      return weights[b_head] - weights[a_head]
    }else if(Object.keys(weights).includes(a_head)){
      return -1
    }else if(Object.keys(weights).includes(b_head)){
      return 1
    }else{
      return (1 - 2 * Number(([a,b].sort() === [a,b])))
    }
  }

const titlesToSink = [
    '中信地产','华夏幸福','恒大','祥生','新都酒店','山水文园','新都'
]

const removeTitles = [
    '当代','绿景','佳兆业','中坤','开元','西安市主管部门',
]

const removeProjects = [
    '茂名南海旅游岛酒店群','惠州南海旅游酒店'
]

export default () => {
    return new Promise((resolve, reject) => {
        axios.get(`${backEndURL}/public/clients`)
        .then(res=>{
          // console.log("res",res.data.payload.find(x=>x.name.includes('西安市')))
          const out = [] as any[]
          sectionNames.forEach(name=>{
            out.push({
                title:nameMapping[name],
                children: res.data.payload
                        .filter((row:any)=>row.type == name)
                        .filter((row:any)=>{
                            for(let i=0; i<removeTitles.length; i++){
                                if(row.name.includes(removeTitles[i])){
                                    return false
                                }
                            }
                            return true
                        })
                        .map((row:any)=>{
                            return {
                                title:row.name,
                                content:row.projects
                                        .filter(x=>{
                                            for(let i=0; i<removeProjects.length; i++){
                                                if(x.includes(removeProjects[i])){
                                                    return false
                                                }
                                            }
                                            return true
                                        })
                                        .sort(sortCities)
                            }
                        }).sort((a:any,b:any)=>{
                            for(let i=0;i<titlesToSink.length;i++){
                                if(a.title.includes(titlesToSink[i])){
                                    return b.content.length - a.content.length/6
                                }
                            }
                            return  b.content.length - a.content.length
                        })
            })
          })
          resolve(out)
        })
        .catch(err=>{
          reject(err)
        })
    })
}
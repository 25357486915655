
/* eslint-disable vue/no-unused-components */
import { defineComponent, PropType, watch, ref } from "vue";
import {scrollTo} from '@/utils'
import scroll from '@/scroll'
import Isotope from '@/components/ux/IsotopeTwo.vue'
import getClients from '../getData/getClients'
import clientImg from '../getData/clientImg'
import clientHTML from '../getData/clientHTML'
import {removeProvs} from '@/assets/provs'
import {windowWidth} from '@/windowWidth'

export default defineComponent({
    name: "App",
    props: {
        headImage: {
            default: () => {return ""},
        },
    },
    components:{
        Isotope
    },
    setup(props){
        const idHash = (str:string) => {
            return btoa(encodeURIComponent(str))
        }

        const pageData = ref()
        const isoInitialize = ref(0)

        // console.log('pageData100')
        getClients().then(res=>{
            pageData.value = res
            isoInitialize.value ++ 
        })

        window.addEventListener('resize', ()=> isoInitialize.value ++ )

        const handleNewWidth = (newWidth:number, mark:string) => {
            const wrapElem = document.querySelector(`._block_piece[mark=${mark}]`) as HTMLElement
            if(!wrapElem) return
            const wrapWidth = wrapElem.getBoundingClientRect().width
            wrapElem.style.paddingLeft = `${(wrapWidth - newWidth)/2}px`
            wrapElem.style.paddingRight = `${(wrapWidth - newWidth)/2}px`
        }

        return {
            removeProvs,
            pageData,
            isoInitialize,
            handleNewWidth,
            scroll,
            scrollTo,
            idHash,
            clientImg,
            clientHTML,
            windowWidth
        }
    }
});

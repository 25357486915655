export default [
    {
        "id": 272,
        "type": "酒管集团",
        "topic": "希尔顿集团",
        "title": "希尔顿集团官网",
        "url": "https://www.hilton.com/zh-hans/corporate/",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1631933792545",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 273,
        "type": "酒管集团",
        "topic": "希尔顿集团",
        "title": "华尔道夫 Waldorf Astoria",
        "url": "https://www.hilton.com/en/waldorf-astoria/",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1631933792545",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 274,
        "type": "酒管集团",
        "topic": "希尔顿集团",
        "title": "LXR",
        "url": "https://lxrhotels3.hilton.com/lxr/",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1631933792545",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 275,
        "type": "酒管集团",
        "topic": "希尔顿集团",
        "title": "康莱德 Conrad",
        "url": "https://www.hilton.com/en/conrad/",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1631933792545",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 276,
        "type": "酒管集团",
        "topic": "希尔顿集团",
        "title": "嘉悦里 Canopy",
        "url": "https://www.hilton.com/en/canopy/",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1631933792545",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 277,
        "type": "酒管集团",
        "topic": "希尔顿集团",
        "title": "Signia",
        "url": "https://www.hilton.com/en/signia-hilton/",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1631933792545",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 278,
        "type": "酒管集团",
        "topic": "希尔顿集团",
        "title": "希尔顿 Hilton",
        "url": "https://www.hilton.com/en/",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1631933792545",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 279,
        "type": "酒管集团",
        "topic": "希尔顿集团",
        "title": "逸林 DoubleTree",
        "url": "https://www.hilton.com/en/doubletree/",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1631933792545",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 280,
        "type": "酒管集团",
        "topic": "希尔顿集团",
        "title": "Tapestry",
        "url": "https://www.hilton.com/en/tapestry/",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1631933792545",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 281,
        "type": "酒管集团",
        "topic": "希尔顿集团",
        "title": "尊盛 Embassy Suites",
        "url": "https://www.hilton.com/en/embassy/",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1631933792545",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 282,
        "type": "酒管集团",
        "topic": "希尔顿集团",
        "title": "Tempo",
        "url": "https://www.hilton.com/en/tempo/",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1631933792545",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 283,
        "type": "酒管集团",
        "topic": "希尔顿集团",
        "title": "Motto",
        "url": "https://www.hilton.com/en/motto/",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1631933792545",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 284,
        "type": "酒管集团",
        "topic": "希尔顿集团",
        "title": "花园酒店 Garden Inn",
        "url": "https://www.hilton.com/en/hilton-garden-inn/",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1631933792545",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 285,
        "type": "酒管集团",
        "topic": "希尔顿集团",
        "title": "欢朋 Hampton",
        "url": "https://www.hilton.com/en/hilton-garden-inn/",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1631933792545",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 286,
        "type": "酒管集团",
        "topic": "希尔顿集团",
        "title": "Tru",
        "url": "https://www.hilton.com/en/tru/",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1631933792545",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 287,
        "type": "酒管集团",
        "topic": "希尔顿集团",
        "title": "Homewood",
        "url": "https://www.hilton.com/en/homewood/",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1631933792545",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 288,
        "type": "酒管集团",
        "topic": "希尔顿集团",
        "title": "Home 2 Suites",
        "url": "https://www.hilton.com/en/home2/",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1631933792545",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 289,
        "type": "酒管集团",
        "topic": "希尔顿集团",
        "title": "Hilton Grand Vacations",
        "url": "https://www.stayhgv.com/",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1631933792545",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 302,
        "type": "酒管集团",
        "topic": "洲际集团",
        "title": "洲际集团官网",
        "url": "https://www.ihg.com/hotels/cn/zh/",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1631933792545",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 303,
        "type": "酒管集团",
        "topic": "洲际集团",
        "title": "六善 Six Senses",
        "url": "https://www.sixsenses.com/en",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1631933792545",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 304,
        "type": "酒管集团",
        "topic": "洲际集团",
        "title": "丽晶 Regent",
        "url": "https://www.ihg.com/regent/hotels/cn/zh/reservation",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1631933792545",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 305,
        "type": "酒管集团",
        "topic": "洲际集团",
        "title": "洲际 InterContinental",
        "url": "https://www.ihg.com/intercontinental/hotels/cn/zh/reservation",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1631933792545",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 306,
        "type": "酒管集团",
        "topic": "洲际集团",
        "title": "金普顿 Kimpton",
        "url": "https://www.ihg.com/kimptonhotels/hotels/cn/zh/reservation",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1631933792545",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 307,
        "type": "酒管集团",
        "topic": "洲际集团",
        "title": "英迪格 Indigo",
        "url": "https://www.ihg.com/hotelindigo/hotels/cn/zh/reservation",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1631933792545",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 308,
        "type": "酒管集团",
        "topic": "洲际集团",
        "title": "voco",
        "url": "https://www.ihg.com/voco/hotels/cn/zh/reservation",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1631933792545",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 309,
        "type": "酒管集团",
        "topic": "洲际集团",
        "title": "华邑 Hua Luxe",
        "url": "https://www.ihg.com/hualuxe/hotels/cn/zh/reservation",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1631933792545",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 310,
        "type": "酒管集团",
        "topic": "洲际集团",
        "title": "皇冠假日 Crowne Plaza",
        "url": "https://www.ihg.com/crowneplaza/hotels/cn/zh/reservation",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1631933792545",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 311,
        "type": "酒管集团",
        "topic": "洲际集团",
        "title": "逸衡 Even Hotels",
        "url": "https://www.ihg.com/evenhotels/hotels/cn/zh/reservation",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1631933792545",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 312,
        "type": "酒管集团",
        "topic": "洲际集团",
        "title": "智选假日 Holiday Inn Express",
        "url": "https://www.ihg.com/holidayinnexpress/hotels/cn/zh/reservation",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1631933792545",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 313,
        "type": "酒管集团",
        "topic": "洲际集团",
        "title": "假日酒店 Holiday Inn",
        "url": "https://www.ihg.com/holidayinn/hotels/cn/zh/reservation",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1631933792545",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 314,
        "type": "酒管集团",
        "topic": "雅高集团",
        "title": "雅高集团官网",
        "url": "https://group.accor.com/en",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1631933792545",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 315,
        "type": "酒管集团",
        "topic": "雅高集团",
        "title": "莱佛士 Raffles",
        "url": "https://group.accor.com/en/brands/luxury/raffles",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1631933792545",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 316,
        "type": "酒管集团",
        "topic": "雅高集团",
        "title": "东方快车 Orient Express",
        "url": "https://group.accor.com/en/brands/luxury/orient-express",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1631933792545",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 317,
        "type": "酒管集团",
        "topic": "雅高集团",
        "title": "悦榕庄 Banyan Tree",
        "url": "https://group.accor.com/en/brands/luxury/banyan-tree#nd-0",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1631933792545",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 318,
        "type": "酒管集团",
        "topic": "雅高集团",
        "title": "Delano",
        "url": "https://group.accor.com/en/brands/luxury/delano#nd-0",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1631933792545",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 319,
        "type": "酒管集团",
        "topic": "雅高集团",
        "title": "费尔曼 Fairmont",
        "url": "https://group.accor.com/en/brands/luxury/fairmont#nd-0",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1631933792545",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 320,
        "type": "酒管集团",
        "topic": "雅高集团",
        "title": "SLS 酒店",
        "url": "https://group.accor.com/en/brands/luxury/sls#nd-0",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1631933792545",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 321,
        "type": "酒管集团",
        "topic": "雅高集团",
        "title": "SO/ 酒店",
        "url": "https://group.accor.com/en/brands/luxury/SO#nd-0",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1631933792545",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 322,
        "type": "酒管集团",
        "topic": "雅高集团",
        "title": "索菲特 Sofitel",
        "url": "https://group.accor.com/en/brands/luxury/sofitel#nd-0",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1631933792545",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 323,
        "type": "酒管集团",
        "topic": "雅高集团",
        "title": "The House of Originals",
        "url": "https://group.accor.com/en/brands/luxury/the-house-of-originals#nd-0",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1631933792545",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 324,
        "type": "酒管集团",
        "topic": "雅高集团",
        "title": "Rixos",
        "url": "https://group.accor.com/en/brands/luxury/rixos#nd-0",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1631933792545",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 325,
        "type": "酒管集团",
        "topic": "雅高集团",
        "title": "onefinestay",
        "url": "https://group.accor.com/en/brands/luxury/onefinestay#nd-0",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1631933792545",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 326,
        "type": "酒管集团",
        "topic": "香格里拉集团",
        "title": "香格里拉集团官网",
        "url": "https://www.shangri-la.com/cn/",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1631933792545",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 327,
        "type": "酒管集团",
        "topic": "香格里拉集团",
        "title": "香格里拉 Shangri-La",
        "url": "https://www.shangri-la.com/cn/find-a-hotel/?choose=ShangriLa|Resorts",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1631933792545",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 328,
        "type": "酒管集团",
        "topic": "香格里拉集团",
        "title": "嘉里酒店 Kerry",
        "url": "https://www.shangri-la.com/cn/find-a-hotel/?choose=Kerry",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1631933792545",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 329,
        "type": "酒管集团",
        "topic": "香格里拉集团",
        "title": "盛贸饭店 Traders",
        "url": "https://www.shangri-la.com/cn/find-a-hotel/?choose=Traders",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1631933792545",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 330,
        "type": "酒管集团",
        "topic": "香格里拉集团",
        "title": "JEN 酒店",
        "url": "https://www.shangri-la.com/cn/hotels/jen",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1631933792545",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 336,
        "type": "酒管集团",
        "topic": "万豪集团",
        "title": "万豪集团官网",
        "url": "http://marriott.com/",
        "added_by": "孙沁园",
        "added_by_id": "15964179670922513",
        "timestamp": "1632377590046",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 337,
        "type": "酒管集团",
        "topic": "其他国内管理公司",
        "title": "华住酒店集团官网",
        "url": "https://www.huazhu.com/",
        "added_by": "卢静涵",
        "added_by_id": "15982320634547631",
        "timestamp": "1632464007578",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 338,
        "type": "酒管集团",
        "topic": "凯悦集团",
        "title": "凯悦集团官网",
        "url": "https://www.hyatt.com/zh-CN/home/",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1632622699823",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 339,
        "type": "酒管集团",
        "topic": "Club Med集团",
        "title": "Club Med",
        "url": "https://www.clubmed.com.cn/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1632720200244",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 340,
        "type": "酒管集团",
        "topic": "Club Med集团",
        "title": "Club Med Joyview ",
        "url": "https://www.clubmed.com.cn/l/clubmedjoyviewconcept",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1632720306815",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 341,
        "type": "酒管集团",
        "topic": "其他国际管理公司",
        "title": "ONYX官网",
        "url": "https://cn.onyx-hospitality.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1632720410507",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 342,
        "type": "酒管集团",
        "topic": "其他国际管理公司",
        "title": "阿玛瑞",
        "url": "https://www.amari.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1632720478772",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 343,
        "type": "酒管集团",
        "topic": "其他国际管理公司",
        "title": "URC官网",
        "url": "https://urbanresortconcepts.com/en/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1632720656550",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 344,
        "type": "酒管集团",
        "topic": "其他国际管理公司",
        "title": "安麓官网",
        "url": "www.ahnluh.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1632720749860",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 345,
        "type": "酒管集团",
        "topic": "其他国际管理公司",
        "title": "安缦官网",
        "url": "https://www.aman.com/?utm_source=baidu&utm_medium=cpc&utm_content=br+-+pure+-+alone&utm_campaign=cn+-+br+-+pure+-+ex&matchtype=1&utm_term=fk1588&creative=14148595644&renqun_youhua=683406&ds_rl=1280921&gclid=CNeI7_-2nvMCFcLOvAodvdAJ8A&gclsrc=ds",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1632720845439",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 346,
        "type": "酒管集团",
        "topic": "其他国内管理公司",
        "title": "安隅官网",
        "url": "http://www.anyuhotel.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1632720877536",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 347,
        "type": "酒管集团",
        "topic": "佰翔集团",
        "title": "佰翔官网",
        "url": "https://www.fliport.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1632720925979",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 348,
        "type": "酒管集团",
        "topic": "佰翔集团",
        "title": "佰翔商务",
        "url": "https://www.fliport.com/hotel?brand_id=1",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1632721297552",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 349,
        "type": "酒管集团",
        "topic": "佰翔集团",
        "title": "佰翔度假",
        "url": "https://www.fliport.com/hotel?brand_id=1",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1632721329472",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 350,
        "type": "酒管集团",
        "topic": "佰翔集团",
        "title": "佰翔琨烁",
        "url": "https://www.fliport.com/hotel?brand_id=3",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1632721404919",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 351,
        "type": "酒管集团",
        "topic": "佰翔集团",
        "title": "佰翔花园",
        "url": "https://www.fliport.com/hotel?brand_id=4",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1632721432855",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 352,
        "type": "酒管集团",
        "topic": "其他国内管理公司",
        "title": "柏联官网",
        "url": "http://www.boliangroup.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1632721700038",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 353,
        "type": "酒管集团",
        "topic": "其他国际管理公司",
        "title": "半岛官网",
        "url": "https://www.peninsula.com/en/default",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1632721787995",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 354,
        "type": "酒管集团",
        "topic": "其他国内管理公司",
        "title": "艺珺",
        "url": "http://www.artels.cn/index.php?m=goods&c=index&a=pinpai#HZ",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1632721902120",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 355,
        "type": "酒管集团",
        "topic": "保利集团",
        "title": "保利官网",
        "url": "https://www.polyhotels.com/zh-CN",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1632722107392",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 356,
        "type": "酒管集团",
        "topic": "保利集团",
        "title": "诺雅",
        "url": "https://www.polyhotels.com/zh-CN/brand/details/7",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1632722174256",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 357,
        "type": "酒管集团",
        "topic": "保利集团",
        "title": "雅途",
        "url": "https://www.polyhotels.com/zh-CN/brand/details/23",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1632722193872",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 358,
        "type": "酒管集团",
        "topic": "保利集团",
        "title": "郡雅",
        "url": "https://www.polyhotels.com/zh-CN/brand/details/14",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1632722214681",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 359,
        "type": "酒管集团",
        "topic": "保利集团",
        "title": "悦雅",
        "url": "https://www.polyhotels.com/zh-CN/brand/details/15",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1632722248120",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 360,
        "type": "酒管集团",
        "topic": "其他国内管理公司",
        "title": "朝林松源",
        "url": "http://www.zhaolingrandhotel.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1632722419768",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 361,
        "type": "酒管集团",
        "topic": "其他国内管理公司",
        "title": "璞邸",
        "url": "http://pudihotel.puxianghui.cn/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1632722540325",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 362,
        "type": "酒管集团",
        "topic": "其他国内管理公司",
        "title": "城市名人官网",
        "url": "http://www.4008266333.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1632722591837",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 363,
        "type": "酒管集团",
        "topic": "钓鱼台美高梅集团",
        "title": "钓鱼台美高梅官网",
        "url": "https://www.dytmgm.com/cn/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1632722858814",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 364,
        "type": "酒管集团",
        "topic": "钓鱼台美高梅集团",
        "title": "钓鱼台",
        "url": "https://www.dytmgm.com/cn/brands.jspx",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1632722900909",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 367,
        "type": "酒管集团",
        "topic": "其他国际管理公司",
        "title": "泛太平洋官网",
        "url": "https://www.panpacific.com/zh.html",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1632723593850",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 368,
        "type": "酒管集团",
        "topic": "凤悦集团",
        "title": "碧桂园凤凰",
        "url": "http://www.funyard-hotels.com/pc/catalog_extend/932",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1632723778642",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 369,
        "type": "酒管集团",
        "topic": "凤悦集团",
        "title": "碧桂园假日",
        "url": "http://www.funyard-hotels.com/pc/catalog_extend/933",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1632723803281",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 370,
        "type": "酒管集团",
        "topic": "凤悦集团",
        "title": "凤悦",
        "url": "http://www.funyard-hotels.com/pc/catalog_extend/926",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1632723828433",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 371,
        "type": "酒管集团",
        "topic": "其他国际管理公司",
        "title": "费尔顿官网",
        "url": "http://www.feltonhotels.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1632723893170",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 372,
        "type": "酒管集团",
        "topic": "富豪集团",
        "title": "富豪官网",
        "url": "https://www.regalhotel.com/regal-hotels-international/tc/home/home.html",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1632724005015",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 373,
        "type": "酒管集团",
        "topic": "富豪集团",
        "title": "富丽华官网",
        "url": "https://www.furama.com/?lang=zh",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1632724052091",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 374,
        "type": "酒管集团",
        "topic": "其他国内管理公司",
        "title": "格兰云天官网",
        "url": "https://gshmhotels.com/brand",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1632724121794",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 375,
        "type": "酒管集团",
        "topic": "其他国内管理公司",
        "title": "观澜湖官网",
        "url": "https://www.missionhillschina.com/zh-hant/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1632724158801",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 376,
        "type": "酒管集团",
        "topic": "瑰丽集团",
        "title": "瑰丽",
        "url": "https://www.rosewoodhotels.com/sc/default",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1632724235318",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 377,
        "type": "酒管集团",
        "topic": "瑰丽集团",
        "title": "芊丽",
        "url": "https://www.khos.com/sc/brand/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1632724276520",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 378,
        "type": "酒管集团",
        "topic": "其他国内管理公司",
        "title": "唐拉雅秀",
        "url": "https://mobil.hnahotels.com/hotelTL.html",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1632724404442",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 379,
        "type": "酒管集团",
        "topic": "涵碧楼集团",
        "title": "青岛涵碧楼",
        "url": "https://www.thelalu.com/qingdao/cn",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1632724562376",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 380,
        "type": "酒管集团",
        "topic": "涵碧楼集团",
        "title": "日月潭涵碧楼",
        "url": "https://www.thelalu.com.tw/zh-tw",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1632724614131",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 381,
        "type": "酒管集团",
        "topic": "其他国内管理公司",
        "title": "阿纳迪",
        "url": "http://cn.theanandi.com/anandi.html",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1632724705128",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 382,
        "type": "酒管集团",
        "topic": "其他国内管理公司",
        "title": "花满地",
        "url": "https://www.slhhotels.cn/hotel/the-mist-hot-spring-hotel?locale=zh_CN",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1632725057231",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 383,
        "type": "酒管集团",
        "topic": "其他国内管理公司",
        "title": "恒大酒店",
        "url": "https://hotel.evergrande.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1632725106343",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 384,
        "type": "酒管集团",
        "topic": "其他国内管理公司",
        "title": "红树林度假",
        "url": "http://www.mangrovetreeresort.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1632725247603",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 385,
        "type": "酒管集团",
        "topic": "其他国际管理公司",
        "title": "嘉佩乐官网",
        "url": "https://www.capellahotels.com/en",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1632726020437",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 386,
        "type": "酒管集团",
        "topic": "其他国际管理公司",
        "title": "婕珞芙官网",
        "url": "http://gfihc.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1632726100775",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 387,
        "type": "酒管集团",
        "topic": "其他国内管理公司",
        "title": "锦江酒店",
        "url": "http://www.jinjianghotels.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1632726178440",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 388,
        "type": "酒管集团",
        "topic": "其他国内管理公司",
        "title": "丽笙",
        "url": "https://www.radissonhotels.com/zh-cn/brand/radisson-blu",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1632726303492",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 389,
        "type": "酒管集团",
        "topic": "九龙仓集团",
        "title": "尼依格罗",
        "url": "https://www.niccolohotels.com/ch/index.html",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1632726709755",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 390,
        "type": "酒管集团",
        "topic": "九龙仓集团",
        "title": "马哥孛罗",
        "url": "https://www.marcopolohotels.com/ch/index.html",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1632726790949",
        "snap": null,
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 428,
        "type": "酒管集团",
        "topic": "朗廷集团",
        "title": "朗廷",
        "url": "https://www.langhamhotels.com/sc/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1633656146249",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 429,
        "type": "酒管集团",
        "topic": "朗廷集团",
        "title": "康得思",
        "url": "https://www.langhamhospitalitygroup.com/sc/brands/cordis-hotels-and-resorts/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1633656174968",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 430,
        "type": "酒管集团",
        "topic": "其他国内管理公司",
        "title": "松赞官网",
        "url": "https://www.songtsam.com/hotel",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1633656354985",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 431,
        "type": "酒管集团",
        "topic": "其他国际管理公司",
        "title": "文华东方",
        "url": "https://www.mandarinoriental.com.cn/?kw=%E6%96%87%E5%8D%8E%E4%B8%9C%E6%96%B9&htl=MOHG&eng=Baidu&src=PPC&utm_source=baidu&utm_medium=cpc&gclid=CPf_1NbUufMCFYQPvAodaIsBAw&gclsrc=ds",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1633656549969",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 432,
        "type": "酒管集团",
        "topic": "其他国内管理公司",
        "title": "亚朵官网",
        "url": "https://www.yaduo.com/zh-CN",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1633656814529",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 1365,
        "type": "酒管集团",
        "topic": "万豪集团",
        "title": "丽思卡尔顿",
        "url": "https://www.ritzcarlton.com/zh-cn",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1634268891639",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 1366,
        "type": "酒管集团",
        "topic": "万豪集团",
        "title": "瑞吉",
        "url": "https://st-regis.marriott.com",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1634269035754",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 1367,
        "type": "酒管集团",
        "topic": "万豪集团",
        "title": "JW万豪",
        "url": "https://jw-marriott.marriott.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1634269070162",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 1368,
        "type": "酒管集团",
        "topic": "万豪集团",
        "title": "丽思卡尔顿隐世",
        "url": "https://www.ritzcarlton.com/en/reserve",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1634269104369",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 1369,
        "type": "酒管集团",
        "topic": "万豪集团",
        "title": "豪华精选",
        "url": "https://the-luxury-collection.marriott.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1634269128714",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 1370,
        "type": "酒管集团",
        "topic": "万豪集团",
        "title": "W 酒店",
        "url": "https://w-hotels.marriott.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1634269151450",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 1371,
        "type": "酒管集团",
        "topic": "万豪集团",
        "title": "艾迪逊",
        "url": "https://www.marriott.com.cn/search/findHotels.mi",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1634269213413",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 1372,
        "type": "酒管集团",
        "topic": "万豪集团",
        "title": "万豪",
        "url": "https://marriott-hotels.marriott.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1634269242036",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 1373,
        "type": "酒管集团",
        "topic": "万豪集团",
        "title": "喜来登",
        "url": "https://sheraton.marriott.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1634269264692",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 1374,
        "type": "酒管集团",
        "topic": "万豪集团",
        "title": "万豪度假会",
        "url": "https://www.marriottvacationclub.com/?loc=IM59%2A1-2G4AEE&cid=corp-mvc-mi-brands-explore-tl-mvc",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1634269287149",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 1375,
        "type": "酒管集团",
        "topic": "万豪集团",
        "title": "德尔塔",
        "url": "https://delta-hotels.marriott.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1634269376737",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 1376,
        "type": "酒管集团",
        "topic": "万豪集团",
        "title": "艾美",
        "url": "https://le-meridien.marriott.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1634269415196",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 1377,
        "type": "酒管集团",
        "topic": "万豪集团",
        "title": "威斯汀",
        "url": "https://westin.marriott.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1634269437068",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 1378,
        "type": "酒管集团",
        "topic": "万豪集团",
        "title": "万丽",
        "url": "https://renaissance-hotels.marriott.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1634269456812",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 1379,
        "type": "酒管集团",
        "topic": "万豪集团",
        "title": "盖洛德",
        "url": "https://www.marriott.com.cn/gaylord-hotels/travel.mi",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1634269481767",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 1380,
        "type": "酒管集团",
        "topic": "万豪集团",
        "title": "万怡",
        "url": "https://courtyard.marriott.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1634269538068",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 1381,
        "type": "酒管集团",
        "topic": "万豪集团",
        "title": "福朋",
        "url": "https://four-points.marriott.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1634269563045",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 1382,
        "type": "酒管集团",
        "topic": "万豪集团",
        "title": "SpringHill Suites",
        "url": "http://springhillsuites.marriott.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1634269587552",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 1383,
        "type": "酒管集团",
        "topic": "万豪集团",
        "title": "普罗提亚",
        "url": "https://protea.marriott.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1634269608465",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 1384,
        "type": "酒管集团",
        "topic": "万豪集团",
        "title": "万枫",
        "url": "https://fairfield.marriott.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1634269631535",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 1385,
        "type": "酒管集团",
        "topic": "万豪集团",
        "title": "AC",
        "url": "https://ac-hotels.marriott.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1634269652071",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 1386,
        "type": "酒管集团",
        "topic": "万豪集团",
        "title": "雅乐轩",
        "url": "https://aloft-hotels.marriott.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1634269677361",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 1387,
        "type": "酒管集团",
        "topic": "万豪集团",
        "title": "Moxy ",
        "url": "https://moxy-hotels.marriott.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1634269700743",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 1388,
        "type": "酒管集团",
        "topic": "万豪集团",
        "title": "傲途格",
        "url": "https://autograph-hotels.marriott.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1634269736547",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 1389,
        "type": "酒管集团",
        "topic": "万豪集团",
        "title": "Design Hotels",
        "url": "https://design-hotels.marriott.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1634269762778",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 1390,
        "type": "酒管集团",
        "topic": "万豪集团",
        "title": "臻品之选",
        "url": "https://tribute-portfolio.marriott.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1634269783386",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 1392,
        "type": "酒管集团",
        "topic": "凯悦集团",
        "title": "柏悦",
        "url": "https://www.hyatt.com/zh-CN/brands/park-hyatt",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1634274803875",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 1393,
        "type": "酒管集团",
        "topic": "凯悦集团",
        "title": "Miraval",
        "url": "https://www.hyatt.com/en-US/brands/miraval",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1634274991430",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 1394,
        "type": "酒管集团",
        "topic": "凯悦集团",
        "title": "君悦",
        "url": "https://www.hyatt.com/brands/grand-hyatt",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1634275552728",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 1396,
        "type": "酒管集团",
        "topic": "凯悦集团",
        "title": "HYATT ZILARA",
        "url": "https://www.hyatt.com/zh-CN/brands/all-inclusive",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1634275755596",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 1397,
        "type": "酒管集团",
        "topic": "凯悦集团",
        "title": "Hyatt",
        "url": "https://www.hyatt.com/brands/hyatt-regency",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1634275947609",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 1398,
        "type": "酒管集团",
        "topic": "凯悦集团",
        "title": "Hyatt Regency",
        "url": "https://www.hyatt.com/zh-CN/brands/hyatt-regency",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1634275977892",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 1399,
        "type": "酒管集团",
        "topic": "凯悦集团",
        "title": "凯悦嘉轩",
        "url": "https://www.hyatt.com/zh-CN/brands/hyatt-place",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1634276130778",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 1400,
        "type": "酒管集团",
        "topic": "凯悦集团",
        "title": "凯悦旅馆",
        "url": "https://www.hyatt.com/zh-CN/brands/hyatt-house",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1634276334947",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 1401,
        "type": "酒管集团",
        "topic": "凯悦集团",
        "title": "逸扉",
        "url": "https://www.hyatt.com/zh-CN/brands/urcove",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1634276364609",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 1402,
        "type": "酒管集团",
        "topic": "凯悦集团",
        "title": "Hyatt Residence Club ",
        "url": "https://www.hyattresidenceclub.com/home.html",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1634276441392",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 1403,
        "type": "酒管集团",
        "topic": "凯悦集团",
        "title": "安达仕",
        "url": "https://www.hyatt.com/zh-CN/brands/andaz",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1634276462383",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 1404,
        "type": "酒管集团",
        "topic": "凯悦集团",
        "title": "阿丽拉",
        "url": "https://www.hyatt.com/zh-CN/brands/alila-hotels-and-resorts",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1634276484575",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 1405,
        "type": "酒管集团",
        "topic": "凯悦集团",
        "title": "汤普森",
        "url": "https://www.hyatt.com/zh-CN/brands/thompson-hotels",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1634276536475",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 1406,
        "type": "酒管集团",
        "topic": "凯悦集团",
        "title": "Hyatt Centric ",
        "url": "https://www.hyatt.com/zh-CN/brands/hyatt-centric",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1634276585651",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 1407,
        "type": "酒管集团",
        "topic": "凯悦集团",
        "title": "Caption By Hyatt ",
        "url": "https://www.hyatt.com/zh-CN/brands/caption-by-hyatt",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1634276641348",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 1408,
        "type": "酒管集团",
        "topic": "凯悦集团",
        "title": "凯悦臻选",
        "url": "https://www.hyatt.com/zh-CN/brands/unbound-collection",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1634276710954",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 1409,
        "type": "酒管集团",
        "topic": "凯悦集团",
        "title": "凯悦悠选",
        "url": "https://www.hyatt.com/zh-CN/brands/destination-by-hyatt",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1634276762470",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 1410,
        "type": "酒管集团",
        "topic": "凯悦集团",
        "title": "凯悦尚选",
        "url": "https://www.hyatt.com/zh-CN/brands/jdv-by-hyatt",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1634276791074",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 1821,
        "type": "酒管集团",
        "topic": "瑰丽集团",
        "title": "新世界",
        "url": "https://newworldhotels.com/en/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1635144128434",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 2109,
        "type": "酒管集团",
        "topic": "涵碧楼集团",
        "title": "南京涵碧楼",
        "url": "https://www.thelalu.com/nanjing/cn",
        "added_by": "吴俊霖",
        "added_by_id": "16230282905609178",
        "timestamp": "1635497457069",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2131,
        "type": "酒管集团",
        "topic": "雅诗阁",
        "title": "",
        "url": "",
        "added_by": "张子朝",
        "added_by_id": "16230280905048066",
        "timestamp": "1637632285185",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 2133,
        "type": "酒管集团",
        "topic": "雅诗阁",
        "title": "雅诗阁ASCOTT",
        "url": "https://www.ascottchina.com/index.aspx",
        "added_by": "张子朝",
        "added_by_id": "16230280905048066",
        "timestamp": "1637647516898",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 2318,
        "type": "酒管集团",
        "topic": "希尔顿集团",
        "title": "Curio Collection 格芮精选",
        "url": "https://www.hilton.com/en/curio/",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1641873254960",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 2507,
        "type": "酒管集团",
        "topic": "温德姆",
        "title": "",
        "url": "",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1645068483644",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 2508,
        "type": "酒管集团",
        "topic": "温德姆",
        "title": "温德姆至尊",
        "url": "https://www.wyndhamhotels.com/wyndham-grand?ICID=IN%3AWR%3A20190403%3ALPTO1%3AOURBRANDS%3AGR",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1645068525327",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 2509,
        "type": "酒管集团",
        "topic": "温德姆",
        "title": "道玺温德姆",
        "url": "https://www.wyndhamhotels.com/dolce?ICID=IN%3AWR%3A20190403%3ALPTO2%3AOURBRANDS%3ADX",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1645068539427",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 2510,
        "type": "酒管集团",
        "topic": "温德姆",
        "title": "温德姆",
        "url": "https://www.wyndhamhotels.com/wyndham?ICID=IN%3AWR%3A20190403%3ALPTO3%3AOURBRANDS%3AHR",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1645068548698",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 2511,
        "type": "酒管集团",
        "topic": "温德姆",
        "title": "爵怡温德姆",
        "url": "https://www.wyndhamhotels.com/tryp?ICID=IN%3AWR%3A20190403%3ALPTO4%3AOURBRANDS%3AWT",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1645068560787",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 2512,
        "type": "酒管集团",
        "topic": "温德姆",
        "title": "Esplendor Boutique Hotels ",
        "url": "https://www.wyndhamhotels.com/esplendor?ICID=IN%3AWR%3A20190403%3ALPTO5%3AOURBRANDS%3AES",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1645068579718",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 2513,
        "type": "酒管集团",
        "topic": "温德姆",
        "title": "Dazzler",
        "url": "https://www.wyndhamhotels.com/dazzler?ICID=IN%3AWR%3A20190403%3ALPTO6%3AOURBRANDS%3ADZ",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1645068589593",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 2514,
        "type": "酒管集团",
        "topic": "温德姆",
        "title": "Trademark",
        "url": "https://www.wyndhamhotels.com/trademark?ICID=IN%3AWR%3A20190403%3ALPTO7%3AOURBRANDS%3ATQ",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1645068598427",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 2515,
        "type": "酒管集团",
        "topic": "温德姆",
        "title": "La Quinta",
        "url": "https://www.wyndhamhotels.com/laquinta?ICID=IN%3AWR%3A20190403%3ALPTO8%3AOURBRANDS%3ALQ",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1645068615900",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 2516,
        "type": "酒管集团",
        "topic": "温德姆",
        "title": "蔚景温德姆",
        "url": "https://www.wyndhamhotels.com/wingate?ICID=IN%3AWR%3A20190403%3ALPTO9%3AOURBRANDS%3AWG",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1645068630094",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 2517,
        "type": "酒管集团",
        "topic": "温德姆",
        "title": "温德姆花园",
        "url": "https://www.wyndhamhotels.com/wyndham-garden?ICID=IN%3AWR%3A20190403%3ALPTO10%3AOURBRANDS%3AGN",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1645068638176",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 2518,
        "type": "酒管集团",
        "topic": "温德姆",
        "title": "AmericInn",
        "url": "https://www.wyndhamhotels.com/americinn?ICID=IN%3AWR%3A20190403%3ALPTO11%3AOURBRANDS%3AAA",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1645068647864",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 2519,
        "type": "酒管集团",
        "topic": "温德姆",
        "title": "华美达",
        "url": "https://www.wyndhamhotels.com/ramada?ICID=IN%3AWR%3A20190403%3ALPTO12%3AOURBRANDS%3ARA",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1645068657945",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 2520,
        "type": "酒管集团",
        "topic": "温德姆",
        "title": "Baymont by Wyndham",
        "url": "https://www.wyndhamhotels.com/baymont?ICID=IN%3AWR%3A20190403%3ALPTO13%3AOURBRANDS%3ABU",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1645068668428",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 2521,
        "type": "酒管集团",
        "topic": "温德姆",
        "title": "麦客达温德姆",
        "url": "https://www.wyndhamhotels.com/microtel?ICID=IN%3AWR%3A20190403%3ALPTO14%3AOURBRANDS%3AMT",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1645068677143",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 2522,
        "type": "酒管集团",
        "topic": "温德姆",
        "title": "戴斯酒店",
        "url": "https://www.wyndhamhotels.com/days-inn?ICID=IN%3AWR%3A20190403%3ALPTO15%3AOURBRANDS%3ADI",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1645068685610",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 2523,
        "type": "酒管集团",
        "topic": "温德姆",
        "title": "速 8 酒店",
        "url": "https://www.wyndhamhotels.com/super-8?ICID=IN%3AWR%3A20190403%3ALPTO16%3AOURBRANDS%3ASE",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1645068702993",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 2524,
        "type": "酒管集团",
        "topic": "温德姆",
        "title": "豪生",
        "url": "https://www.wyndhamhotels.com/hojo?ICID=IN%3AWR%3A20190403%3ALPTO17%3AOURBRANDS%3AHJ",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1645068710743",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 2525,
        "type": "酒管集团",
        "topic": "温德姆",
        "title": "Travelodge ",
        "url": "https://www.wyndhamhotels.com/travelodge?ICID=IN%3AWR%3A20190403%3ALPTO18%3AOURBRANDS%3ATL",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1645068719580",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 2526,
        "type": "酒管集团",
        "topic": "温德姆",
        "title": "Hawthorn Suites by Wyndham ",
        "url": "https://www.wyndhamhotels.com/hawthorn-extended-stay?ICID=IN%3AWR%3A20190403%3ALPTO19%3AOURBRANDS%3ABH",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1645068731398",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 2527,
        "type": "酒管集团",
        "topic": "都喜",
        "title": "",
        "url": "",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1645068940143",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 2528,
        "type": "酒管集团",
        "topic": "都喜",
        "title": "都喜天阙",
        "url": "https://www.dusit.com/dusitprincess/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1645068983281",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 2529,
        "type": "酒管集团",
        "topic": "都喜",
        "title": "都喜天丽",
        "url": "https://www.dusit.com/dusitthani/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1645068997942",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 2530,
        "type": "酒管集团",
        "topic": "都喜",
        "title": "都喜泰丽",
        "url": "https://www.dusit.com/dusitdevarana/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1645069010954",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 2531,
        "type": "酒管集团",
        "topic": "都喜",
        "title": "都喜",
        "url": "https://www.dusit.com/dusitd2/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1645069025281",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 2532,
        "type": "酒管集团",
        "topic": "瑰丽集团",
        "title": "CARLYLE&CO.",
        "url": "https://www.rosewoodhotelgroup.com/zh-cn/our-brands/carlyle-co",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1645069093808",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 2533,
        "type": "酒管集团",
        "topic": "瑰丽集团",
        "title": "ASAYA",
        "url": "https://www.rosewoodhotelgroup.com/zh-cn/our-brands/asaya",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1645069105531",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 2545,
        "type": "酒管集团",
        "topic": "华住",
        "title": "",
        "url": "",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1645667625694",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2546,
        "type": "酒管集团",
        "topic": "华住",
        "title": "CitiGo",
        "url": "https://www.huazhu.com/CitiGO",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1645667639947",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2547,
        "type": "酒管集团",
        "topic": "华住",
        "title": "城际酒店",
        "url": "https://www.huazhu.com/Intercity",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1645667649313",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2548,
        "type": "酒管集团",
        "topic": "华住",
        "title": "海友",
        "url": "https://www.huazhu.com/Haiyou",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1645667658170",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2549,
        "type": "酒管集团",
        "topic": "华住",
        "title": "汉庭",
        "url": "https://www.huazhu.com/Hanting",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1645667670312",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2550,
        "type": "酒管集团",
        "topic": "华住",
        "title": "花间堂",
        "url": "https://www.huazhu.com/HuaJianTang",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1645667680127",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2551,
        "type": "酒管集团",
        "topic": "华住",
        "title": "桔子",
        "url": "https://www.huazhu.com/Juzi",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1645667689033",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2552,
        "type": "酒管集团",
        "topic": "华住",
        "title": "桔子水晶",
        "url": "https://www.huazhu.com/JuziShuijing",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1645667699443",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2553,
        "type": "酒管集团",
        "topic": "华住",
        "title": "漫心",
        "url": "https://www.huazhu.com/Manxin",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1645667706544",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2554,
        "type": "酒管集团",
        "topic": "华住",
        "title": "美居",
        "url": "https://www.huazhu.com/Mercure",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1645667713676",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2555,
        "type": "酒管集团",
        "topic": "华住",
        "title": "美爵",
        "url": "https://www.huazhu.com/GrandMercure",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1645667724463",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2556,
        "type": "酒管集团",
        "topic": "华住",
        "title": "美仑",
        "url": "https://www.huazhu.com/Meilun",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1645667732067",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2557,
        "type": "酒管集团",
        "topic": "华住",
        "title": "诺富特",
        "url": "https://www.huazhu.com/Novotel",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1645667739531",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2558,
        "type": "酒管集团",
        "topic": "华住",
        "title": "全季",
        "url": "https://www.huazhu.com/Quanji",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1645667747003",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2559,
        "type": "酒管集团",
        "topic": "华住",
        "title": "施柏阁",
        "url": "https://www.huazhu.com/Steigenberger",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1645667754581",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2560,
        "type": "酒管集团",
        "topic": "华住",
        "title": "施柏阁大观",
        "url": "https://www.huazhu.com/steigenbergericons",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1645667762760",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2561,
        "type": "酒管集团",
        "topic": "华住",
        "title": "宋品",
        "url": "https://www.huazhu.com/SongHotels",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1645667770391",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2562,
        "type": "酒管集团",
        "topic": "瑰丽集团",
        "title": "禧玥",
        "url": "https://www.huazhu.com/Xiyue",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1645667778517",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 2563,
        "type": "酒管集团",
        "topic": "瑰丽集团",
        "title": "星程",
        "url": "https://www.huazhu.com/Xingcheng",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1645667788242",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 2564,
        "type": "酒管集团",
        "topic": "华住",
        "title": "怡莱",
        "url": "https://www.huazhu.com/Elan",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1645667795952",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2565,
        "type": "酒管集团",
        "topic": "华住",
        "title": "宜必思",
        "url": "https://www.huazhu.com/Ibis",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1645667803081",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2566,
        "type": "酒管集团",
        "topic": "华住",
        "title": "宜必思尚品",
        "url": "https://www.huazhu.com/IbisStyles",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1645667814286",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2567,
        "type": "酒管集团",
        "topic": "华住",
        "title": "永乐半山",
        "url": "https://www.huazhu.com/lebanshan",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1645667830719",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2568,
        "type": "酒管集团",
        "topic": "德胧集团",
        "title": "",
        "url": "",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1645767197731",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2569,
        "type": "酒管集团",
        "topic": "德胧集团",
        "title": "方外",
        "url": "https://www.kaiyuanhotels.com/brand?id=1",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646037922542",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2570,
        "type": "酒管集团",
        "topic": "德胧集团",
        "title": "观堂",
        "url": "https://www.kaiyuanhotels.com/brand?id=2",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646037942493",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2571,
        "type": "酒管集团",
        "topic": "德胧集团",
        "title": "芳草地度假酒店",
        "url": "https://www.kaiyuanhotels.com/brand?id=3",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646037953109",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2572,
        "type": "酒管集团",
        "topic": "德胧集团",
        "title": "开元名都",
        "url": "https://www.kaiyuanhotels.com/brand?id=4",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646037963639",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2573,
        "type": "酒管集团",
        "topic": "德胧集团",
        "title": "开元名庭",
        "url": "https://www.kaiyuanhotels.com/brand?id=5",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646037976725",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2574,
        "type": "酒管集团",
        "topic": "德胧集团",
        "title": "曼居酒店",
        "url": "https://www.kaiyuanhotels.com/brand?id=6",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646037987922",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2575,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "7天酒店",
        "url": "http://www.7daysinn.cn/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2576,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "7天优品",
        "url": "http://www.7daysinn.cn/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2577,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "IU酒店",
        "url": "http://www.iuinns.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2578,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "J Hotel ",
        "url": "https://www.jhotel-shanghai.com/en/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2579,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "ZMAX HOTELS",
        "url": "http://www.zmax-hotels.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2580,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "白玉兰酒店",
        "url": "http://www.magnotel.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2581,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "百时快捷",
        "url": "http://www.jinjianginns.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2582,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "潮漫",
        "url": "http://www.chaoman.vip/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2583,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "诚悦租住",
        "url": "https://hotel.bestwehotel.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2584,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "非繁·轻简",
        "url": "https://hotel.bestwehotel.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2585,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "非繁城品",
        "url": "https://hotel.bestwehotel.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2586,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "枫渡",
        "url": "http://www.fontoohotels.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2587,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "瑰悦",
        "url": "http://royard.cn/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2588,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "好眠国际酒店",
        "url": "http://www.wyn88.com/index/wynym_revision.html",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2589,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "皇家金煦",
        "url": "https://hotel.bestwehotel.com/Brands/?brandName=ROT",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2590,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "金广快捷",
        "url": "http://www.jinjianginns.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2591,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "金熙",
        "url": "https://hotel.bestwehotel.com/Brands/?brandName=TI",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2592,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "锦江",
        "url": "http://www.jinjianghotels.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2593,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "锦江都城",
        "url": "http://www.metropolohotels.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2594,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "锦江之星",
        "url": "http://www.jinjianginns.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2595,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "锦江之星风尚",
        "url": "http://www.jinjianginns.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2596,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "锦江之星品尚",
        "url": "http://www.jinjianginns.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2597,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "憬黎",
        "url": "https://hotel.bestwehotel.com/HotelSearch/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2598,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "暻阁",
        "url": "https://hotel.bestwehotel.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2599,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "凯里亚德",
        "url": "http://www.kmhotel.net/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2600,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "康铂",
        "url": "http://www.campanilechina.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2601,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "昆仑",
        "url": "https://hotel.bestwehotel.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2602,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "丽柏",
        "url": "https://www.radissonhotels.com/en-us/brand/park-inn",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2603,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "丽筠",
        "url": "https://www.radissonhotels.com/zh-cn/brand/radisson",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2604,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "丽芮",
        "url": "https://www.radissonhotels.com/zh-cn/brand/radisson-red",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2605,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "丽笙",
        "url": "https://www.radissonhotels.com/zh-cn/brand/radisson-blu",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2606,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "丽笙精选",
        "url": "https://www.radissonhotels.com/zh-cn/brand/radisson-collection",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2607,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "丽怡",
        "url": "https://www.radissonhotels.com/zh-cn/brand/country-inn",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2608,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "丽亭酒店",
        "url": "https://www.radissonhotels.com/en-us/brand/park-plaza",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2609,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "麗枫",
        "url": "https://www.lavandehotels.com/html/cn/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2610,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "欧暇地中海",
        "url": "https://www.wyn88.com/index/wnshjjd_revisionnew.html",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2611,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "派酒店",
        "url": "http://www.paihotels.cc/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2612,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "锐品",
        "url": "https://hotel.bestwehotel.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2613,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "维纳斯度假村酒店",
        "url": "http://www.wyn88.com/index/wyndjcjd_revision.html",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2614,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "维纳斯国际酒店",
        "url": "http://www.wyn88.com/index/wyngjjd_revision.html",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2615,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "维纳斯皇家酒店",
        "url": "http://www.wyn88.com/index/wnshjjd_revision.html",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2616,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "维也纳3好",
        "url": "http://www.wyn88.com/index/wyn3hjd_revision.html",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2617,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "维也纳国际酒店",
        "url": "https://www.wyn88.com/index/wyngjjd_revision.html",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2618,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "维也纳酒店",
        "url": "https://www.wyn88.com/index/wynjd_revision.html",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2619,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "维也纳智好酒店",
        "url": "http://www.wyn88.com/index/wynzh_revision.html",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2620,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "窝趣公寓",
        "url": "https://www.wowqu.cn/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2621,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "希岸",
        "url": "http://www.xanahotelle.com/html/Competitive-Products-Style-2.htm",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2622,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "希岸Deluxe",
        "url": "http://www.xanahotelle.com/html/Competitive-Products-Style-1.htm",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2623,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "希岸轻雅",
        "url": "http://www.xanahotelle.com/html/Competitive-Products-Style-3.htm",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2624,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "亦岚",
        "url": "http://www.xanahotelle.com/html/Competitive-Products-Style-3.htm",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2625,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "郁锦香",
        "url": "https://hotel.bestwehotel.com/Brands/?brandName=GOT",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2626,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "原拓",
        "url": "https://hotel.bestwehotel.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2627,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "云居",
        "url": "https://hotel.bestwehotel.com/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2628,
        "type": "酒管集团",
        "topic": "锦江",
        "title": "喆啡",
        "url": "http://www.jjcoffetel.cc/",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2629,
        "type": "酒管集团",
        "topic": "首旅如家",
        "title": "建国饭店",
        "url": "https://www.bthhotels.com/About/JianGuo",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2630,
        "type": "酒管集团",
        "topic": "首旅如家",
        "title": "京伦饭店",
        "url": "https://www.bthhotels.com/About/jlfd",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2631,
        "type": "酒管集团",
        "topic": "首旅如家",
        "title": "首旅南苑",
        "url": "https://www.bthhotels.com/About/slny",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2632,
        "type": "酒管集团",
        "topic": "首旅如家",
        "title": "建国铂萃酒店",
        "url": "https://www.bthhotels.com/about/bocui",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2633,
        "type": "酒管集团",
        "topic": "首旅如家",
        "title": "逸扉酒店",
        "url": "https://www.bthhotels.com/about/urcove",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2634,
        "type": "酒管集团",
        "topic": "首旅如家",
        "title": "南山休闲会馆",
        "url": "https://www.bthhotels.com/About/nshg",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2635,
        "type": "酒管集团",
        "topic": "首旅如家",
        "title": "和颐至尊酒店",
        "url": "https://www.bthhotels.com/About/ytel",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2636,
        "type": "酒管集团",
        "topic": "首旅如家",
        "title": "和颐至尚酒店",
        "url": "https://www.bthhotels.com/About/ytelzs",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2637,
        "type": "酒管集团",
        "topic": "首旅如家",
        "title": "和颐至格酒店",
        "url": "https://www.bthhotels.com/About/ytelzg",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2638,
        "type": "酒管集团",
        "topic": "首旅如家",
        "title": "璞隐酒店",
        "url": "https://www.bthhotels.com/About/py",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2639,
        "type": "酒管集团",
        "topic": "首旅如家",
        "title": "扉缦酒店",
        "url": "https://www.bthhotels.com/About/jlfm",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2640,
        "type": "酒管集团",
        "topic": "首旅如家",
        "title": "万信至格酒店",
        "url": "https://www.bthhotels.com/About/wanxin",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2641,
        "type": "酒管集团",
        "topic": "首旅如家",
        "title": "如家精选酒店",
        "url": "https://www.bthhotels.com/About/plus",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2642,
        "type": "酒管集团",
        "topic": "首旅如家",
        "title": "如家商旅酒店",
        "url": "https://www.bthhotels.com/About/rjsl",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2643,
        "type": "酒管集团",
        "topic": "首旅如家",
        "title": "阿曼·莫兰迪酒店",
        "url": "https://www.bthhotels.com/About/amun",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2644,
        "type": "酒管集团",
        "topic": "首旅如家",
        "title": "艾扉酒店",
        "url": "https://www.bthhotels.com/About/aifei",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2645,
        "type": "酒管集团",
        "topic": "首旅如家",
        "title": "柏丽艾尚国际酒店",
        "url": "https://www.bthhotels.com/About/blas",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2646,
        "type": "酒管集团",
        "topic": "首旅如家",
        "title": "YUNIK酒店",
        "url": "https://www.bthhotels.com/About/yunik",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2647,
        "type": "酒管集团",
        "topic": "首旅如家",
        "title": "嘉虹酒店",
        "url": "https://www.bthhotels.com/About/jhjd",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2648,
        "type": "酒管集团",
        "topic": "首旅如家",
        "title": "云上四季·尚品酒店",
        "url": "https://www.bthhotels.com/About/shangpin",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2649,
        "type": "酒管集团",
        "topic": "首旅如家",
        "title": "如家酒店",
        "url": "https://www.bthhotels.com/About/homeinn",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2650,
        "type": "酒管集团",
        "topic": "首旅如家",
        "title": "莫泰酒店",
        "url": "https://www.bthhotels.com/About/motel",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2651,
        "type": "酒管集团",
        "topic": "首旅如家",
        "title": "驿居酒店",
        "url": "https://www.bthhotels.com/About/slyj",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2652,
        "type": "酒管集团",
        "topic": "首旅如家",
        "title": "雅客e家",
        "url": "https://www.bthhotels.com/About/ykej",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2653,
        "type": "酒管集团",
        "topic": "首旅如家",
        "title": "欣燕都",
        "url": "https://www.bthhotels.com/About/xyd",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2654,
        "type": "酒管集团",
        "topic": "首旅如家",
        "title": "如家小镇",
        "url": "https://www.bthhotels.com/About/rjxz",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2655,
        "type": "酒管集团",
        "topic": "首旅如家",
        "title": "漫趣乐园",
        "url": "https://www.bthhotels.com/About/manqu",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2656,
        "type": "酒管集团",
        "topic": "首旅如家",
        "title": "素柏云酒店",
        "url": "https://www.bthhotels.com/About/sby",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2657,
        "type": "酒管集团",
        "topic": "首旅如家",
        "title": "睿柏云酒店",
        "url": "https://www.bthhotels.com/About/rby",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2658,
        "type": "酒管集团",
        "topic": "首旅如家",
        "title": "派柏云酒店",
        "url": "https://www.bthhotels.com/About/pby",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2659,
        "type": "酒管集团",
        "topic": "首旅如家",
        "title": "诗柏云酒店",
        "url": "https://www.bthhotels.com/About/shiby",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2660,
        "type": "酒管集团",
        "topic": "首旅如家",
        "title": "云上四季民宿",
        "url": "https://www.bthhotels.com/About/yssjms",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2661,
        "type": "酒管集团",
        "topic": "首旅如家",
        "title": "UP华驿精选",
        "url": "https://www.bthhotels.com/about/uphuayi",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2662,
        "type": "酒管集团",
        "topic": "首旅如家",
        "title": "华驿精选",
        "url": "https://www.bthhotels.com/about/huayijingxuan",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2663,
        "type": "酒管集团",
        "topic": "首旅如家",
        "title": "华驿酒店",
        "url": "https://www.bthhotels.com/about/huayi",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2664,
        "type": "酒管集团",
        "topic": "首旅如家",
        "title": "驿禾酒店",
        "url": "https://www.bthhotels.com/about/huayi",
        "added_by": "吴佳丽",
        "added_by_id": "16278674835059480",
        "timestamp": "1646102768976",
        "snap": "",
        "hidden": 0,
        "worth": 0,
        "dd_file": null,
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2684,
        "type": "酒管集团",
        "topic": "Club Med集团",
        "title": "ClubMed 品牌介绍 Public CN-EN 2018.pdf",
        "url": "",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1652837610442",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": {
            "fileId": "49366777558",
            "creator": "1453255730913567",
            "spaceId": "237753122",
            "fileName": "ClubMed 品牌介绍 Public CN-EN 2018.pdf",
            "filePath": "/07 @品牌/01 国际品牌_MOU 合同 品牌标准 品牌说明/10 Club Med/品牌说明/ClubMed 品牌介绍 Public CN-EN 2018.pdf",
            "fileSize": 9997111,
            "fileType": "file",
            "modifier": "1453255730913567",
            "parentId": "49366754549",
            "thumbnail": "https://down.dingtalk.com/ddmedia/iAEKAqNqcGcDAQTNA8AFzQLQBtoAIYQBpAsdo2QCqmYjrzMzJN9tCUMDzwAAAXGCnGDQBM39HQcACAA.jpg",
            "createTime": "2021-12-27T03:30:58Z",
            "modifyTime": "2021-12-27T03:30:58Z",
            "contentType": "document",
            "fileExtension": "pdf"
        },
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 2685,
        "type": "酒管集团",
        "topic": "香格里拉集团",
        "title": "香格里拉品牌介绍 201901.pdf",
        "url": "",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1652837704030",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": {
            "fileId": "49366719781",
            "creator": "1453255730913567",
            "spaceId": "237753122",
            "fileName": "香格里拉品牌介绍 201901.pdf",
            "filePath": "/07 @品牌/01 国际品牌_MOU 合同 品牌标准 品牌说明/18 香格里拉/品牌说明/香格里拉品牌介绍 201901.pdf",
            "fileSize": 42952607,
            "fileType": "file",
            "modifier": "1453255730913567",
            "parentId": "49366779922",
            "thumbnail": "https://down.dingtalk.com/ddmedia/iAEKAqNqcGcDAQTNBjMFzQMZBtoAI4QBpAsQWhACqruU1hSUBFdLKaMDzwAAAXGCqiGrBM4AAcxkBwAIAA.jpg",
            "createTime": "2021-12-27T03:30:59Z",
            "modifyTime": "2021-12-27T03:30:59Z",
            "contentType": "document",
            "fileExtension": "pdf"
        },
        "page": "tools",
        "marker": "国际"
    },
    {
        "id": 2692,
        "type": "酒管集团",
        "topic": "德胧集团",
        "title": "Ruby 瑰宝酒店",
        "url": "https://www.betterwood.com/consumer/gb",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1657099111202",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2693,
        "type": "酒管集团",
        "topic": "德胧集团",
        "title": "Mora Space 穆拉酒店",
        "url": "https://www.betterwood.com/consumer/ml",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1657099141928",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国内"
    },
    {
        "id": 2694,
        "type": "酒管集团",
        "topic": "德胧集团",
        "title": "Barceló 巴塞罗",
        "url": "https://www.betterwood.com/consumer/bsl",
        "added_by": "谭天",
        "added_by_id": "16091190299794776",
        "timestamp": "1657099165330",
        "snap": "",
        "hidden": 0,
        "worth": 5,
        "dd_file": "",
        "page": "tools",
        "marker": "国内"
    }
]
import axios from 'axios'
import {backEndURL} from '@/siteConfig'
import {ref} from 'vue'
import hotels from './toolListData/hotels'
import management_companies from './toolListData/management_groups'

const toolListData = ref({
    '最佳新酒店':[
        {
            title:'',
            children:[] as any[],
        },
    ],
    '绿色酒店':[
        {
            title:'',
            children:[] as any[],
        },
    ],
    '管理集团及品牌':[
        {
            title:'',
            children:[] as any[],
        },
    ],
} )
const hotelTopics = hotels.map(x=>x.topic).reduce((a,b)=>a.includes(b)?a:[...a,b], [] as any)
const mcTopics = management_companies
                    .map(x=>x.topic)
                    .reduce((a,b)=>a.includes(b)?a:[...a,b], [] as any)

console.log('mcTopics', mcTopics)

toolListData.value = {
    '最佳新酒店':hotelTopics.map(h=>{
        return {
            title:h,
            children:hotels.filter(hotel=>hotel.topic == h && !hotel.hidden)
        }
    }).filter(x=>x.children.length>0)
    .filter(h=>h.title.includes('新') && !h.title.includes('环保'))
    .sort((a,b)=>a.children.length > b.children.length ? -1 : 1),

    '绿色酒店':hotelTopics.map(h=>{
        return {
            title:h,
            children:hotels.filter(hotel=>hotel.topic == h && !hotel.hidden)
        }
    }).filter(x=>x.children.length>0)
    .filter(x=>x.title!='特色酒店案例')
    .filter(h=>(!h.title.includes('新')) || h.title.includes('环保'))
    .sort((a,b)=>a.children.length > b.children.length ? -1 : 1),
    
    '管理集团及品牌':mcTopics.map(x=>{
        return {
            title:x,
            children:management_companies.filter(mc=>mc.topic == x && !mc.hidden)
        }
    }).filter(x=>x.children.length>0)
    .sort((a,b)=>{
        if(
            (a.children[0].marker == '国际')
            && (a.children[0].marker != '国际')
        ){
            return -1
        }
        else if(
            (a.children[0].marker != '国际')
            && (a.children[0].marker == '国际')
        ){
            return 1
        }
        a.children.length > b.children.length ? -1 : 1
    }),
}
console.log('toolListData', toolListData.value)

export default toolListData



export default {
    "万科":"logo_wanke.jpg",
    "万达":"logo_wanda.jpg",
    "万华":"logo_wanhua.gif",
    "上海世博":"logo_shanghaishibo.gif",
    "上海地产集团":"logo_shanghaidichan.gif",
    "世茂集团":"logo_shimao.gif",
    "中交股份":"logo_zhongguojiaojian.jpg",
    "中创集团":"logo_zhongchuang.jpg",
    "中国东方航空":"logo_congfanghangkkong.jpg",
    "中国中铁":"logo_zhongtie.gif",
    "中国人寿":"logo_zhongguorenshou.jpg",
    "中国冶金":"logo_zhongguoyejin.gif",
    "中国国电":"logo_zhongguoguodian.gif",
    "中国大唐":"logo_zhongguodatang.gif",
    "中国平安":"logo_zhongguopingan.gif",
    "中国烟草":"logo_zhongguoyancao.jpg",
    "中国电信":"logo_zhongguodianxin.gif",
    "中国石油":"logo_zhongguoshiyou.gif",
    "中国银泰":"logo_zhongguoyintai.gif",
    "中国银行":"logo_zhongguoyinhang.jpg",
    "中国长江三峡集团公司":"logo_sanxiajituan.gif",
    "中坤集团":"logo_zhongkun.gif",
    "中建集团":"logo_zhongjian.jpg",
    "中房集团":"logo_zhongfang.gif",
    "中海投资":"logo_zhonghaitouzi.gif",
    "中粮集团":"logo_zhongliang.jpg",
    "中航国际":"logo_zhonghangguoji.jpg",
    "中航地产":"logo_zhonghangdichan.gif",
    "中船集团":"logo_zhongchuan.jpg",
    "中银国际":"logo_zhongyinjijin.gif",
    "中银基金":"logo_zhongyinjijin.gif",
    "云南城投":"logo_yunnanchengtou.jpg",
    "云投集团":"logo_yuntou.gif",
    "交银施罗德":"logo_ciaoyinshiluode.gif",
    "佳兆业":"logo_jiazhaoye.gif",
    "保利":"logo_baolidichan.gif",
    "信诚":"logo_zhongxinxincheng.gif",
    "农业银行":"logo_nongyeyinhang.gif",
    "北京当代MOMA":"logo_dangdaimoma.gif",
    "华为":"logo_huawei.gif",
    "华侨城":"logo_huaqiaocheng.gif",
    "华夏幸福基业":"logo_huaxiaxingfujiye.jpg",
    "华天大酒店":"logo_huatiandajiudian.gif",
    "华润":"logo_huarun.gif",
    "厦门建发集团":"logo_jianfajituan.jpg",
    "厦门海投集团":"logo_xiamenhaitou.jpg",
    "厦门航空港":"logo_xiamenhangkonggang.gif",
    "合众人寿":"logo_hezhongrenshou.gif",
    "四川能投":"logo_sichuannengtou.jpg",
    "国丰钢铁":"logo_guofenggangtie.gif",
    "国家电网":"logo_guojiadianwang.jpg",
    "国投集团":"logo_guotoujiduan.gif",
    "国贸控股":"logo_guomaokonggu.jpg",
    "国锐集团":"logo_guorui.jpg",
    "复地地产":"logo_fudi.gif",
    "字节跳动":"logo_zijietiaodong.jpg",
    "安信":"logo_zhonganxin.gif",
    "安徽旅游集团":"logo_anhuishenglvyoujituan.gif",
    "富国基金":"logo_fuguojijin.gif",
    "山水文园集团":"logo_shanshuiwenyuan.jpg",
    "广东机场管理集团":"logo_guandongjichang.jpg",
    "广州地铁":"logo_guangzhouditie.gif",
    "广西旅投":"logo_guangxilvtou.gif",
    "建设银行":"logo_jiansheyinhang.jpg",
    "开元旅业集团":"logo_kaiyuan.gif",
    "恒大集团":"logo_hengdajituan.jpg",
    "成都文旅":"logo_chengduwenlv.gif",
    "招商地产":"logo_zhaoshangdichan.gif",
    "摩根斯坦利":"logo_morganstanley.gif",
    "新都酒店":"logo_xindujiudian.gif",
    "杭州地铁":"logo_hangzhouditie.gif",
    "格力地产":"logo_gelidichan.gif",
    "武汉城投":"logo_wuhanchengtou.gif",
    "海富通基金":"logo_haifutongjijin.gif",
    "海尔":"logo_haier.gif",
    "海旅集团":"logo_hailvjituan.gif",
    "海昌":"logo_haichang.gif",
    "海航集团":"logo_haihangjituan.gif",
    "海银金控":"logo_haiyinjinkong.gif",
    "深圳地铁":"logo_shenzhendite.jpg",
    "深圳宝安机场":"logo_shenzhenjichang.jpg",
    "特区建发":"logo_tequjianfa.jpg",
    "珠江实业":"logo_zhujiangshiye.gif",
    "神华集团":"logo_shenhuajituan.gif",
    "祥生集团":"logo_xiangsheng.jpg",
    "粤海集团":"logo_yuehai.jpg",
    "纳爱斯":"logo_naaisi.gif",
    "绿地集团":"logo_lvdikonggu.jpg",
    "绿城中国":"logo_lvchengzhongguo.jpg",
    "绿景集团":"logo_lvjing.jpg",
    "联想":"logo_lianxiang.gif",
    "腾讯":"logo_tengxun.jpg",
    "融创":"logo_ronchuang.jpg",
    "融汇集团":"logo_ronghui.jpg",
    "西安市主管部门":"logo_xianzhengfu.jpg",
    "贵州高投东南置业":"logo_guizhougaotou.jpg",
    "贵高速集团":"logo_guizhougaosu.gif",
    "远洋地产":"logo_yuanyangdichan.gif",
    "鄂旅投":"logo_elvtou.jpg",
    "金地集团":"logo_jindi.gif",
    "金科地产":"logo_jinke.gif",
    "长信基金":"logo_changxinjijin.gif",
    "长虹":"logo_changhong.gif",
    "阳光保险":"logo_yangguangbaoxian.gif",
    "首创置业":"logo_shouchuangzhiye.gif",
    "鲁能地产":"logo_luneng.gif",
    "龙湖":"logo_longhudichan.gif",
    "中信地产":"logo_zhongxindichan.jpg",
    "美国饭店协会":"ahla.png?versionId=CAEQLhiBgMCC3Z769BciIDdlM2E1MmNhMmU4ZTQwYmY5MDNhZDFkOTFkYTJiODQx",
    "融通旅发":"融通.png",
    "拈花湾":"拈花湾.jpg",
    "雅高酒店集团":"雅高.png",
  }
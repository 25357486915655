
/* eslint-disable vue/no-unused-components */
import { defineComponent, PropType, watch, ref, onMounted } from "vue";
import {scrollTo} from '@/utils'
import scroll from '@/scroll'
import Isotope from '@/components/ux/IsotopeTwo.vue'
import clientImg from '../getData/clientImg'
import pageDataCases from '@/pageData/cases'
import {removeProvs} from '@/assets/provs'
import {windowWidth} from '@/windowWidth'

export default defineComponent({
    name: "App",
    props: {
        headImage: {
            default: () => {return ""},
        },
    },
    components:{
        Isotope
    },
    setup(props){
        const idHash = (str:string) => {
            return btoa(encodeURIComponent(str))
        }

        const isoInitialize = ref(0)

        const pageData = pageDataCases.blocks.find(x=>x.title=="项目分类")!.children
        onMounted(()=>{
            isoInitialize.value ++ 
        })

        window.addEventListener('resize', ()=> isoInitialize.value ++ )

        const handleNewWidth = (newWidth:number, mark:string) => {
            const wrapElem = document.querySelector(`._block_piece[mark=${mark}]`) as HTMLElement
            if(!wrapElem) return
            const wrapWidth = wrapElem.getBoundingClientRect().width
            wrapElem.style.paddingLeft = `${(wrapWidth - newWidth)/2}px`
            wrapElem.style.paddingRight = `${(wrapWidth - newWidth)/2}px`
        }


        const colors = [
            '#FFE46D',
            '#B8A0EC',
            '#33C5B9',
            '#3E74D1',
            '#45B53C',
            '#B8A0EC',
        ]

        const filters = [
            'invert(92%) sepia(62%) saturate(1430%) hue-rotate(321deg) brightness(83%) contrast(104%);',
            'invert(78%) sepia(35%) saturate(3039%) hue-rotate(203deg) brightness(76%) contrast(96%);',
            'invert(64%) sepia(46%) saturate(598%) hue-rotate(126deg) brightness(74%) contrast(87%);',
            'invert(46%) sepia(98%) saturate(2900%) hue-rotate(203deg) brightness(67%) contrast(87%);',
            'invert(61%) sepia(10%) saturate(3119%) hue-rotate(68deg) brightness(77%) contrast(81%);',
            'invert(83%) sepia(16%) saturate(5992%) hue-rotate(199deg) brightness(73%) contrast(98%);',
        ]


        return {
            pageData,
            isoInitialize,
            handleNewWidth,
            scroll,
            scrollTo,
            idHash,
            clientImg,
            colors,
            filters,
            removeProvs,
            windowWidth
        }
    }
});



/* eslint-disable vue/no-unused-components */
import { defineComponent, onMounted, watch, nextTick } from "vue";
import scroll from '@/scroll'
import pageData from '@/pageData/services'
import currTab from './currTab'
import LineThrough from '@/components/LineThrough.vue'
import {useRouter, useRoute} from 'vue-router'

export default defineComponent({
    props: {
        headImage: {
            default: "",
        },
        scrollCount: {
            default:0
        },
        scrollId: {
            default:''
        }
    },
    components:{
        LineThrough
    },
    setup(props){
        const idHash = (str:string) => {
            return btoa(encodeURIComponent(str))
        }

        // console.log('pageData', pageData)

        const blocks = Object.values(pageData)

        watch(()=>props.scrollCount, ()=>{
            const target = document.getElementById(props.scrollId)
            if(!target) return
            const targetTop = target.getBoundingClientRect().top
            // console.log('targetTop', targetTop)

            window.scroll(0, targetTop + scroll.value - 130)
        })

        const route = useRoute()

        const tabScroll = () => {
            nextTick(()=>{
                const tab = route.params.tab as string
                const targetId = encodeURIComponent(tab)
                const targetElem = document.getElementById(targetId)
                if(!targetElem) return
                const targetTop = targetElem.getBoundingClientRect().top
                const offsetPosition = targetTop + window.pageYOffset - 120;
                // console.log('offsetPosition', offsetPosition)

                nextTick(()=>{
                    window.scrollTo({
                        top: offsetPosition,
                        // behavior: "smooth"
                    });
                })
            })
        }

        onMounted(()=>{
            tabScroll()
        })

        watch(()=>route.params.tab,()=>{
            nextTick(()=>{
                tabScroll()
            })
        })

        return {
            currTab,
            pageData,
            blocks,
            scroll,
            idHash,
            router:useRouter()
        }
    }
});


import { defineComponent, ref, computed, watch, PropType } from 'vue'
import ScrollEnter from '@/components/ScrollEnter.vue'


interface articleCard {
    abstract: string;
    datetime: string;
    id: number;
    image: string;
    title: string;
}

export default defineComponent({
  name: "SocialMedia",
  props:{
      article:{
          default: () => {
                return {
                    abstract: '',
                    datetime: '',
                    id: -1,
                    image: '',
                    title: '',
                }
            },
          type: Object as PropType<articleCard>
      },
      fixSize:{default: false},
      _inactive:{default:false}
  },
  components:{ScrollEnter},
  setup(props){
      const injectOSS = (url:string) => {
          if(url.slice(0,8)=='/uploads'){
              return 'https://oss.huamei2001.com/hm2001/articles/oldhm2001'+url
          }else{
              return url
          }
      }


      const abstractDisplay = (str:string) =>{
          const clean = str.replace(new RegExp(`&nbsp(;)?`, 'g'), '')
            if(clean.length <= 100)return clean
            return clean.slice(0, 100) + '...'
      }
    return { 
        injectOSS,
        abstractDisplay,
        window
    }
  },
})
